import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../../store';
import { ReduxLoadingStatus, ReduxError } from '../../../interfaces/redux';
import { initialApiStatusState } from '../../data/const';
import {
  getAllShoppingList,
  addShoppingList,
  getByIdShoppingList,
  updateShoppingList,
  deleteByIdShoppingList,
  deleteByIdsShoppingList,
  getByFilterShoppingList,
  ShoppingListState,
} from './shoppingListActions';
import { Item, ListType, ShoppingList } from '../../../interfaces/backend';
import { DeleteResponseStatus } from '../../../interfaces/api';
import {
  addItem,
  deleteByIdItem,
  deleteByIdsItem,
  updateItem,
} from '../item/itemActions';
import {
  copyShoppingList,
  sampleShoppingList,
  updateShoppingListsOrder,
} from './shoppingListCustomActions';
import { resetShoppingList, updateItemsOrder } from '../item/itemCustomActions';
import { isAdmin } from '../../../utils/shoppingList';

export const initialState: ShoppingListState = {
  data: {
    records: [],
    isInitialized: false,
  },
  commonApiStatus: {
    ...initialApiStatusState,
  },
  customApiStatus: {
    updateOrderApiStatus: {
      loadingStatus: ReduxLoadingStatus.IDLE,
    },
    copyApiStatus: {
      loadingStatus: ReduxLoadingStatus.IDLE,
    },
    sampleShoppingListApiStatus: {
      loadingStatus: ReduxLoadingStatus.IDLE,
    },
  },
};

const updateData = (
  state: ShoppingListState,
  record: ShoppingList
): ShoppingList[] => [
  ...state.data.records.filter((sl) => sl.id !== record.id),
  record,
];

const updateDataWithoutUpdatingItems = (
  state: ShoppingListState,
  record: ShoppingList
): ShoppingList[] => {
  const existingList = state.data.records.find((sl) => sl.id === record.id);
  const shoppingListTagIds = record.itemTags?.map((tag) => tag.id);
  const items =
    existingList?.items?.map((item) => ({
      ...item,
      itemTags: item.itemTags?.filter((tag) =>
        shoppingListTagIds?.includes(tag.id)
      ),
    })) || [];
  return [
    ...state.data.records.filter((sl) => sl.id !== record.id),
    { ...record, items },
  ];
};

const updateItemData = (
  state: ShoppingListState,
  record: Item
): ShoppingList[] => {
  const updatedRecords = state.data.records.map((sl) => {
    if (sl.id === record.shoppingListId) {
      const existingTags = sl.itemTags || [];
      const existingTagIds = existingTags.map((t) => t.id);
      const newTags =
        record.itemTags?.filter((tag) => !existingTagIds.includes(tag.id)) ||
        [];
      return {
        ...sl,
        itemTags: [...existingTags, ...newTags],
        items: [...(sl.items?.filter((a) => a.id !== record.id) || []), record],
      };
    }
    return sl;
  });
  return updatedRecords;
};

const filterItems = (
  state: ShoppingListState,
  itemIds: string[]
): ShoppingList[] => {
  const updatedRecords = state.data.records.map((sl) => ({
    ...sl,
    items: sl.items?.filter((a) => !itemIds.includes(a.id)),
  }));
  return updatedRecords;
};

const entityName = 'ShoppingList';
export const shoppingListSlice = createSlice({
  name: entityName,
  initialState,
  reducers: {
    clearShoppingListData: () => initialState,
    clearShoppingListApiStatus: (state) => ({
      ...state,
      commonApiStatus: {
        ...initialApiStatusState,
      },
      customApiStatus: {
        ...initialState.customApiStatus,
      },
    }),
    deleteByIdShoppingListReducer: (state, action: PayloadAction<string>) => ({
      ...state,
      data: {
        ...state.data,
        records: state.data?.records.filter((rec) => rec.id !== action.payload),
      },
    }),
  },

  extraReducers: (builder) => {
    builder.addCase(getAllShoppingList.pending, (state) => ({
      ...state,
      commonApiStatus: {
        ...state.commonApiStatus,
        getAllApiStatus: {
          loadingStatus: ReduxLoadingStatus.LOADING,
        },
      },
    }));
    builder.addCase(getAllShoppingList.fulfilled, (state, action) => ({
      ...state,
      data: { ...state.data, records: action.payload },

      commonApiStatus: {
        ...state.commonApiStatus,
        getAllApiStatus: {
          loadingStatus: ReduxLoadingStatus.COMPLETED,
        },
      },
    }));
    builder.addCase(getAllShoppingList.rejected, (state, action) => ({
      ...state,
      commonApiStatus: {
        ...state.commonApiStatus,
        getAllApiStatus: {
          loadingStatus: ReduxLoadingStatus.FAILED,
          error: action.payload ? action.payload : action.error,
          errorNote:
            (action?.payload as ReduxError)?.message ||
            `Error while fetching ${entityName} records`,
        },
      },
    }));
    builder.addCase(getByIdShoppingList.pending, (state) => ({
      ...state,
      commonApiStatus: {
        ...state.commonApiStatus,
        getByIdApiStatus: {
          loadingStatus: ReduxLoadingStatus.LOADING,
        },
      },
    }));
    builder.addCase(getByIdShoppingList.fulfilled, (state, action) => ({
      ...state,
      data: { ...state.data, records: updateData(state, action.payload) },
      commonApiStatus: {
        ...state.commonApiStatus,
        getByIdApiStatus: {
          loadingStatus: ReduxLoadingStatus.COMPLETED,
        },
      },
    }));
    builder.addCase(getByIdShoppingList.rejected, (state, action) => ({
      ...state,
      commonApiStatus: {
        ...state.commonApiStatus,
        getByIdApiStatus: {
          loadingStatus: ReduxLoadingStatus.FAILED,
          error: action.payload ? action.payload : action.error,
          errorNote:
            (action?.payload as ReduxError)?.message ||
            `Error while fetching ${entityName} record`,
        },
      },
    }));
    builder.addCase(getByFilterShoppingList.pending, (state) => ({
      ...state,
      commonApiStatus: {
        ...state.commonApiStatus,
        getByFilterApiStatus: {
          loadingStatus: ReduxLoadingStatus.LOADING,
        },
      },
    }));
    builder.addCase(getByFilterShoppingList.fulfilled, (state, action) => ({
      ...state,
      data: { ...state.data, records: action.payload, isInitialized: true },
      commonApiStatus: {
        ...state.commonApiStatus,
        getByFilterApiStatus: {
          loadingStatus: ReduxLoadingStatus.COMPLETED,
        },
      },
    }));
    builder.addCase(getByFilterShoppingList.rejected, (state, action) => ({
      ...state,
      commonApiStatus: {
        ...state.commonApiStatus,
        getByFilterApiStatus: {
          loadingStatus: ReduxLoadingStatus.FAILED,
          error: action.payload ? action.payload : action.error,
          errorNote:
            (action?.payload as ReduxError)?.message ||
            `Error while fetching ${entityName} records`,
        },
      },
    }));
    builder.addCase(addShoppingList.pending, (state) => ({
      ...state,
      commonApiStatus: {
        ...state.commonApiStatus,
        addApiStatus: {
          loadingStatus: ReduxLoadingStatus.LOADING,
        },
      },
    }));
    builder.addCase(addShoppingList.fulfilled, (state, action) => ({
      ...state,
      data: { ...state.data, records: updateData(state, action.payload) },
      commonApiStatus: {
        ...state.commonApiStatus,
        addApiStatus: {
          loadingStatus: ReduxLoadingStatus.COMPLETED,
        },
      },
    }));
    builder.addCase(addShoppingList.rejected, (state, action) => ({
      ...state,
      commonApiStatus: {
        ...state.commonApiStatus,
        addApiStatus: {
          loadingStatus: ReduxLoadingStatus.FAILED,
          error: action.payload ? action.payload : action.error,
          errorNote:
            (action?.payload as ReduxError)?.message ||
            `Error while creating ${entityName} record`,
        },
      },
    }));
    builder.addCase(updateShoppingList.pending, (state) => ({
      ...state,
      commonApiStatus: {
        ...state.commonApiStatus,
        updateApiStatus: {
          loadingStatus: ReduxLoadingStatus.LOADING,
        },
      },
    }));
    builder.addCase(updateShoppingList.fulfilled, (state, action) => ({
      ...state,
      data: {
        ...state.data,
        records: updateDataWithoutUpdatingItems(state, action.payload),
      },
      commonApiStatus: {
        ...state.commonApiStatus,
        updateApiStatus: {
          loadingStatus: ReduxLoadingStatus.COMPLETED,
        },
      },
    }));
    builder.addCase(updateShoppingList.rejected, (state, action) => ({
      ...state,
      commonApiStatus: {
        ...state.commonApiStatus,
        updateApiStatus: {
          loadingStatus: ReduxLoadingStatus.FAILED,
          error: action.payload ? action.payload : action.error,
          errorNote:
            (action?.payload as ReduxError)?.message ||
            `Error while updating ${entityName} record`,
        },
      },
    }));
    builder.addCase(deleteByIdShoppingList.pending, (state) => ({
      ...state,
      commonApiStatus: {
        ...state.commonApiStatus,
        deleteByIdApiStatus: {
          loadingStatus: ReduxLoadingStatus.LOADING,
        },
      },
    }));
    builder.addCase(deleteByIdShoppingList.fulfilled, (state, action) => ({
      ...state,
      data: {
        ...state.data,
        records:
          state.data?.records.filter((item) => item.id !== action.payload) ||
          [],
      },
      commonApiStatus: {
        ...state.commonApiStatus,
        deleteByIdApiStatus: {
          loadingStatus: ReduxLoadingStatus.COMPLETED,
        },
      },
    }));
    builder.addCase(deleteByIdShoppingList.rejected, (state, action) => ({
      ...state,
      commonApiStatus: {
        ...state.commonApiStatus,
        deleteByIdApiStatus: {
          loadingStatus: ReduxLoadingStatus.FAILED,
          error: action.payload ? action.payload : action.error,
          errorNote:
            (action?.payload as ReduxError)?.message ||
            `Error while deleting ${entityName} record`,
        },
      },
    }));
    builder.addCase(deleteByIdsShoppingList.pending, (state) => ({
      ...state,
      commonApiStatus: {
        ...state.commonApiStatus,
        deleteApiStatus: {
          loadingStatus: ReduxLoadingStatus.LOADING,
        },
      },
    }));
    builder.addCase(deleteByIdsShoppingList.fulfilled, (state, action) => {
      const deletedIds = action.payload
        .filter((item) => item.status === DeleteResponseStatus.PASS)
        .map((item) => item.id);
      const failedIds = action.payload
        .filter((item) => item.status === DeleteResponseStatus.FAIL)
        .map((item) => item.id);
      return {
        ...state,
        data: {
          ...state.data,
          records:
            state.data?.records.filter(
              (item) => !deletedIds.includes(item.id)
            ) || [],
        },
        commonApiStatus: {
          ...state.commonApiStatus,
          deleteApiStatus: {
            loadingStatus: ReduxLoadingStatus.COMPLETED,
            error: action.payload,
            errorNote: failedIds.length
              ? `Error while deleting few ${entityName} records`
              : undefined,
          },
        },
      };
    });
    builder.addCase(deleteByIdsShoppingList.rejected, (state, action) => ({
      ...state,
      commonApiStatus: {
        ...state.commonApiStatus,
        deleteApiStatus: {
          loadingStatus: ReduxLoadingStatus.FAILED,
          error: action.payload ? action.payload : action.error,
          errorNote:
            (action?.payload as ReduxError)?.message ||
            `Error while deleting ${entityName} records`,
        },
      },
    }));

    builder.addCase(updateShoppingListsOrder.pending, (state) => ({
      ...state,
      customApiStatus: {
        ...state.customApiStatus,
        updateOrderApiStatus: {
          loadingStatus: ReduxLoadingStatus.LOADING,
        },
      },
    }));
    builder.addCase(updateShoppingListsOrder.fulfilled, (state, action) => ({
      ...state,
      data: {
        ...state.data,
        records: state.data?.records.map((list) => {
          const index =
            action.payload && isAdmin(list)
              ? action.payload.indexOf(list.id)
              : list.order;
          return {
            ...list,
            order: index,
          };
        }),
      },
      customApiStatus: {
        ...state.customApiStatus,
        updateOrderApiStatus: {
          loadingStatus: ReduxLoadingStatus.COMPLETED,
        },
      },
    }));
    builder.addCase(updateShoppingListsOrder.rejected, (state, action) => ({
      ...state,
      customApiStatus: {
        ...state.customApiStatus,
        updateOrderApiStatus: {
          loadingStatus: ReduxLoadingStatus.FAILED,
          error: action.payload ? action.payload : action.error,
          errorNote:
            (action?.payload as ReduxError)?.message ||
            `Error while reordering ${entityName} records`,
        },
      },
    }));

    // ///////////////////////// Extra ACTIONS ///////////////////////////
    builder.addCase(copyShoppingList.pending, (state) => ({
      ...state,
      customApiStatus: {
        ...state.customApiStatus,
        copyApiStatus: {
          loadingStatus: ReduxLoadingStatus.LOADING,
        },
      },
    }));
    builder.addCase(copyShoppingList.fulfilled, (state, action) => ({
      ...state,
      data: { ...state.data, records: updateData(state, action.payload) },
      customApiStatus: {
        ...state.customApiStatus,
        copyApiStatus: {
          loadingStatus: ReduxLoadingStatus.COMPLETED,
        },
      },
    }));
    builder.addCase(copyShoppingList.rejected, (state, action) => ({
      ...state,
      customApiStatus: {
        ...state.customApiStatus,
        copyApiStatus: {
          loadingStatus: ReduxLoadingStatus.FAILED,
          error: action.payload ? action.payload : action.error,
          errorNote:
            (action?.payload as ReduxError)?.message ||
            `Error while copying ${entityName} record`,
        },
      },
    }));

    builder.addCase(sampleShoppingList.pending, (state) => ({
      ...state,
      customApiStatus: {
        ...state.customApiStatus,
        sampleShoppingListApiStatus: {
          loadingStatus: ReduxLoadingStatus.LOADING,
        },
      },
    }));
    builder.addCase(sampleShoppingList.fulfilled, (state, action) => ({
      ...state,
      data: { ...state.data, records: updateData(state, action.payload) },
      customApiStatus: {
        ...state.customApiStatus,
        sampleShoppingListApiStatus: {
          loadingStatus: ReduxLoadingStatus.COMPLETED,
        },
      },
    }));
    builder.addCase(sampleShoppingList.rejected, (state, action) => ({
      ...state,
      customApiStatus: {
        ...state.customApiStatus,
        sampleShoppingListApiStatus: {
          loadingStatus: ReduxLoadingStatus.FAILED,
          error: action.payload ? action.payload : action.error,
          errorNote:
            (action?.payload as ReduxError)?.message ||
            `Error while creating sample ${entityName} record`,
        },
      },
    }));
    // ///////////////////////// ITEM ACTIONS ///////////////////////////
    // Only Update Data as state is used from item store
    builder.addCase(updateItem.fulfilled, (state, action) => ({
      ...state,
      data: { ...state.data, records: updateItemData(state, action.payload) },
    }));

    builder.addCase(addItem.fulfilled, (state, action) => ({
      ...state,
      data: { ...state.data, records: updateItemData(state, action.payload) },
    }));

    builder.addCase(deleteByIdItem.fulfilled, (state, action) => ({
      ...state,
      data: { ...state.data, records: filterItems(state, [action.payload]) },
    }));

    builder.addCase(deleteByIdsItem.fulfilled, (state, action) => {
      const deletedIds = action.payload
        .filter((item) => item.status === DeleteResponseStatus.PASS)
        .map((item) => item.id);
      return {
        ...state,
        data: {
          ...state.data,
          records: filterItems(state, deletedIds),
        },
      };
    });

    builder.addCase(updateItemsOrder.fulfilled, (state, action) => ({
      ...state,
      data: {
        ...state.data,
        records: state.data?.records.map((list) => {
          if (list.id === action.payload?.shoppingListId) {
            const updatedItems = list.items?.map((item) => {
              const itemIndex = action.payload?.ids
                ? action.payload.ids.indexOf(item.id)
                : item.order;
              return {
                ...item,
                order: itemIndex,
              };
            });
            return {
              ...list,
              items: updatedItems,
            };
          }
          return list;
        }),
      },
    }));

    builder.addCase(resetShoppingList.fulfilled, (state, action) => ({
      ...state,
      data: {
        ...state.data,
        records: state.data?.records.map((list) => {
          if (list.id === action.payload) {
            const updatedItems = list.items?.map((item) => ({
              ...item,
              purchased: false,
              quantityToPurchase:
                item.quantityToPurchase === (undefined || null) ||
                list.listType === ListType.STOCK
                  ? item.quantityToPurchase
                  : 0,
              quantityInStock:
                item.quantityToPurchase === (undefined || null)
                  ? item.quantityInStock
                  : 0,
            }));
            return {
              ...list,
              items: updatedItems,
            };
          }
          return list;
        }),
      },
    }));
  },
});

export const {
  clearShoppingListData,
  clearShoppingListApiStatus,
  deleteByIdShoppingListReducer,
} = shoppingListSlice.actions;

export const getShoppingListStore = (state: RootState) =>
  state.shoppingListStore;

export * from './shoppingListActions';

export default shoppingListSlice.reducer;
