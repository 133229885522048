import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../../store';

export interface AppDetails {
  version: string;
  code: string;
  platform: string;
  time: string;
  recaptcha?: string;
  email?: string;
  password?: string;
  token?: string;
}

export interface UserState {
  data: { appDetails?: AppDetails };
}

export const initialState: UserState = {
  data: {
    appDetails: undefined,
  },
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    clearAppData: () => initialState,
    logoutAppData: (state) => ({
      ...state,
      data: {
        ...state.data,
        appDetails: state.data.appDetails
          ? { ...state.data.appDetails, email: undefined, password: undefined }
          : undefined,
      },
    }),
    updateAppData: (state, action) => ({
      ...state,
      data: { ...state.data, appDetails: action.payload },
    }),
  },

  extraReducers: () => {},
});

export const { clearAppData, logoutAppData, updateAppData } = appSlice.actions;

export const getAppStore = (state: RootState) => state.appStore;

export const getAppDetails = (state: RootState) =>
  state.appStore.data.appDetails;

export const getIsMobileApp = (state: RootState) =>
  !!state.appStore.data.appDetails;

export default appSlice.reducer;
