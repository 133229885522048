import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getAllApi,
  getByIdApi,
  addApi,
  updateApi,
  deleteByIdsApi,
  deleteByIdApi,
  getByFilterApi,
} from '../../../apis/commonApis';
import { Feedback } from '../../../interfaces/backend';
import { DeleteResponse } from '../../../interfaces/api';
import { ReduxCommonApisStatus, ReduxError } from '../../../interfaces/redux';
import { getErrorMessage } from '../../../utils/api';
import { FormatTextType, formatText } from '../../../utils/common';

export interface FeedbackState {
  data: { records: Feedback[]; isInitialized: boolean };
  commonApiStatus: ReduxCommonApisStatus;
}

const entityName = 'Feedback';
const entityNameLowerCase = formatText(entityName, FormatTextType.LOWER);

export const getAllFeedback = createAsyncThunk<Feedback[]>(
  `${entityNameLowerCase}/getAll`,
  async (_, { rejectWithValue, getState }) => {
    try {
      const feedbackStore = (getState() as any).feedbackStore as FeedbackState;
      if (feedbackStore.data.records.length > 0) {
        return [...feedbackStore.data.records];
      }
      return await getAllApi(entityName);
    } catch (err) {
      return rejectWithValue({
        error: JSON.stringify(err),
        message: getErrorMessage(err),
        requestPayload: {},
        url: `${entityNameLowerCase}/getAll`,
      } as ReduxError);
    }
  }
);

export const getByIdFeedback = createAsyncThunk<
  Feedback,
  { id: string; hardRefresh?: boolean }
>(
  `${entityNameLowerCase}/getById`,
  async ({ id, hardRefresh }, { rejectWithValue, getState }) => {
    try {
      if (!hardRefresh) {
        const feedbackStore = (getState() as any)
          .feedbackStore as FeedbackState;
        const record = feedbackStore.data.records?.find((x) => x.id === id);
        if (record) {
          return { ...record };
        }
      }
      return await getByIdApi(entityName, id);
    } catch (err) {
      return rejectWithValue({
        error: JSON.stringify(err),
        message: getErrorMessage(err),
        requestPayload: { id },
        url: `${entityNameLowerCase}/getById`,
      } as ReduxError);
    }
  }
);

export const getByFilterFeedback = createAsyncThunk<
  Feedback[],
  { filter: Partial<Feedback>; hardRefresh?: boolean }
>(
  `${entityNameLowerCase}/getByFIlter`,
  async ({ filter: record, hardRefresh }, { rejectWithValue, getState }) => {
    try {
      const feedbackStore = (getState() as any).feedbackStore as FeedbackState;
      if (feedbackStore.data.isInitialized && !hardRefresh) {
        return [...feedbackStore.data.records];
      }
      return await getByFilterApi(entityName, record);
    } catch (err) {
      return rejectWithValue({
        error: JSON.stringify(err),
        message: getErrorMessage(err),
        requestPayload: { record },
        url: `${entityNameLowerCase}/getByFIlter`,
      } as ReduxError);
    }
  }
);

export const addFeedback = createAsyncThunk<Feedback, Partial<Feedback>>(
  `${entityNameLowerCase}/add`,
  async (record: Partial<Feedback>, { rejectWithValue }) => {
    try {
      return await addApi(entityName, record);
    } catch (err) {
      return rejectWithValue({
        error: JSON.stringify(err),
        message: getErrorMessage(err),
        requestPayload: { record },
        url: `${entityNameLowerCase}/add`,
      } as ReduxError);
    }
  }
);

export const updateFeedback = createAsyncThunk<
  Feedback,
  { id: string; record: Partial<Feedback> }
>(
  `${entityNameLowerCase}/update`,
  async ({ id, record }, { rejectWithValue }) => {
    try {
      return await updateApi(entityName, id, record);
    } catch (err) {
      return rejectWithValue({
        error: JSON.stringify(err),
        message: getErrorMessage(err),
        requestPayload: { id, record },
        url: `${entityNameLowerCase}/update`,
      } as ReduxError);
    }
  }
);

export const deleteByIdFeedback = createAsyncThunk<string, string>(
  `${entityNameLowerCase}/deleteById`,
  async (id: string, { rejectWithValue }) => {
    try {
      await deleteByIdApi(entityName, id);
      return id;
    } catch (err) {
      return rejectWithValue({
        error: JSON.stringify(err),
        message: getErrorMessage(err),
        requestPayload: { id },
        url: `${entityNameLowerCase}/deleteById`,
      } as ReduxError);
    }
  }
);

export const deleteByIdsFeedback = createAsyncThunk<DeleteResponse[], string[]>(
  `${entityNameLowerCase}/delete`,
  async (ids: string[], { rejectWithValue }) => {
    try {
      return await deleteByIdsApi(entityName, ids);
    } catch (err) {
      return rejectWithValue({
        error: JSON.stringify(err),
        message: getErrorMessage(err),
        requestPayload: { ids },
        url: `/delete`,
      } as ReduxError);
    }
  }
);
