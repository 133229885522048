// ////////////////////////

export const ItemQuery = `
  id  
  name 
  quantityInStock 
  quantityToPurchase 
  shoppingListId
  unit  {
    id
    name
    abbreviation
  }
  purchased 
  itemTags {
    id
    name
    color
  } 
  notes 
  order
  createdAt
  updatedAt
`;

export const ItemAddFields1 = `$shoppingListId: String!, $name: String!, $purchased: Boolean, $notes: String, $itemTags: [ItemTagInput!], $unitId: String, $quantityInStock: Float, $quantityToPurchase: Float`;
export const ItemAddFields2 = `shoppingListId: $shoppingListId,name: $name,purchased:$purchased,notes:$notes, itemTags: $itemTags, unitId: $unitId, quantityInStock: $quantityInStock, quantityToPurchase: $quantityToPurchase`;
export const ItemUpdateFields1 = `$id: String!,$shoppingListId: String, $name: String, $purchased: Boolean, $notes: String, $itemTags: [ItemTagInput!], $unitId: String, $quantityInStock: Float, $quantityToPurchase: Float`;
export const ItemUpdateFields2 = `id: $id, shoppingListId: $shoppingListId,name: $name,purchased:$purchased,notes:$notes, itemTags: $itemTags, unitId: $unitId, quantityInStock: $quantityInStock, quantityToPurchase: $quantityToPurchase`;
export const ItemFilterFields1 = ` $shoppingListId: String, $name: String, $purchased: Boolean `;
export const ItemFilterFields2 = ` shoppingListId: $shoppingListId,name: $name,purchased:$purchased `;

// ////////////////////////
export const ShoppingListFields = ` 
    id
    permission
    sharedByName
    name
    listType
    favourite
    notes 
    order
    itemTags {
      id
      name
      color
    }
    items {
      ${ItemQuery}
    }
    shoppingListFriends {
      id
      shoppingListId
      friendId 
      permission
    }
    createdAt
    updatedAt
`;

export const ShoppingListAddFields1 = `$userId: Float!, $name: String!, $listType: String, $favourite: Boolean, $notes: String, $itemTags: [ItemTagInput!]`;
export const ShoppingListAddFields2 = `userId: $userId,name: $name, listType: $listType, favourite:$favourite,notes:$notes, itemTags: $itemTags`;
export const ShoppingListUpdateFields1 = `$id: String!,$name: String,$listType: String, $favourite: Boolean, $notes: String, $itemTags: [ItemTagInput!], $shoppingListFriends: [ShoppingListFriendInput!]`;
export const ShoppingListUpdateFields2 = `id: $id, name: $name,listType: $listType,favourite:$favourite,notes:$notes, itemTags: $itemTags, shoppingListFriends: $shoppingListFriends`;
export const ShoppingListFilterFields1 = `$userId: Float!,$name: String, $favourite: Boolean `;
export const ShoppingListFilterFields2 = `userId: $userId,name: $name,favourite:$favourite `;
// ////////////////////////
export const UnitQuery = `
    id
    name
    abbreviation
`;

// ////////////////////////
export const ItemTagQuery = `
  id 
  name 
  color 
`;

// ////////////////////////
export const UserConfigQuery = `
  id
  userId
  shoppingListId
  unitId
  shoppingListFilters
  itemFilters
  shoppingListConfirmDelete
  itemConfirmDelete
  createdAt
  updatedAt
`;

export const UserConfigUpdateFields1 = `
$id: String!,
$shoppingListId: String, 
$unitId: String, 
$shoppingListFilters: String, 
$itemFilters: String, 
$shoppingListConfirmDelete: Boolean, 
$itemConfirmDelete: Boolean`;

export const UserConfigUpdateFields2 = `
id: $id, 
shoppingListId: $shoppingListId,
unitId: $unitId,
shoppingListFilters:$shoppingListFilters,
itemFilters:$itemFilters, 
shoppingListConfirmDelete: $shoppingListConfirmDelete, 
itemConfirmDelete: $itemConfirmDelete`;

export const UserConfigFilterFields1 = ` $userId: Int!`;
export const UserConfigFilterFields2 = ` userId: $userId`;
// ////////////////////////

export const FeedbackQuery = `
  id   
  user  {
    userId
    firstName
    lastName
    email
    phone
  }
  feedback 
  reply 
  note
  rating 
  status
  createdAt
  updatedAt
`;

export const FeedbackAddFields1 = `$userId: Float!, $feedback: String!,  $rating: Float!`;
export const FeedbackAddFields2 = `userId: $userId, feedback: $feedback, rating:$rating`;
export const FeedbackUpdateFields1 = `$id: String!,$reply: String, $note: String, $status: String`;
export const FeedbackUpdateFields2 = `id: $id, reply: $reply,note: $note,status:$status`;
export const FeedbackFilterFields1 = ` $userId: Float, $status: String, $rating: Float `;
export const FeedbackFilterFields2 = ` userId: $userId,status: $status,rating:$rating `;

// ////////////////////////
export const FriendUserQuery = `
  userId    
  firstName 
  lastName 
  email 
`;

export const FriendQuery = `
  id    
  friendId 
  myStatus 
  firendStatus
  friendUser {
    ${FriendUserQuery}
  }
  createdAt
  updatedAt
`;

export const FriendAddFields1 = `$id: String!`;
export const FriendAddFields2 = `id: $id`;
export const FriendUpdateFields1 = `$id: String!, $firendStatus: String!`;
export const FriendUpdateFields2 = `id: $id, firendStatus:$firendStatus`;
export const FriendFilterFields1 = `$userId: Float`;
export const FriendFilterFields2 = `userId: $userId`;

// ////////////////////////
export const getQueryFields = (entityName: string) => {
  switch (entityName) {
    case 'ShoppingList':
      return ShoppingListFields;
    case 'Unit':
      return UnitQuery;
    case 'ItemTag':
      return ItemTagQuery;
    case 'Item':
      return ItemQuery;
    case 'UserConfig':
      return UserConfigQuery;
    case 'Feedback':
      return FeedbackQuery;
    case 'Friend':
      return FriendQuery;
    case 'FriendUser':
      return FriendUserQuery;
    default:
      return '';
  }
};

export const getAddFields1 = (entityName: string) => {
  switch (entityName) {
    case 'ShoppingList':
      return ShoppingListAddFields1;
    case 'Unit':
      return '';
    case 'ItemTag':
      return '';
    case 'Item':
      return ItemAddFields1;
    case 'Feedback':
      return FeedbackAddFields1;
    case 'Friend':
      return FriendAddFields1;
    default:
      return '';
  }
};

export const getAddFields2 = (entityName: string) => {
  switch (entityName) {
    case 'ShoppingList':
      return ShoppingListAddFields2;
    case 'Unit':
      return '';
    case 'ItemTag':
      return '';
    case 'Item':
      return ItemAddFields2;
    case 'Feedback':
      return FeedbackAddFields2;
    case 'Friend':
      return FriendAddFields2;
    default:
      return '';
  }
};

export const getUpdateFields1 = (entityName: string) => {
  switch (entityName) {
    case 'ShoppingList':
      return ShoppingListUpdateFields1;
    case 'Unit':
      return '';
    case 'ItemTag':
      return '';
    case 'Item':
      return ItemUpdateFields1;
    case 'UserConfig':
      return UserConfigUpdateFields1;
    case 'Feedback':
      return FeedbackUpdateFields1;
    case 'Friend':
      return FriendUpdateFields1;
    default:
      return '';
  }
};

export const getUpdateFields2 = (entityName: string) => {
  switch (entityName) {
    case 'ShoppingList':
      return ShoppingListUpdateFields2;
    case 'Unit':
      return '';
    case 'ItemTag':
      return '';
    case 'Item':
      return ItemUpdateFields2;
    case 'UserConfig':
      return UserConfigUpdateFields2;
    case 'Feedback':
      return FeedbackUpdateFields2;
    case 'Friend':
      return FriendUpdateFields2;
    default:
      return '';
  }
};

export const getFilterFields1 = (entityName: string) => {
  switch (entityName) {
    case 'ShoppingList':
      return ShoppingListFilterFields1;
    case 'Unit':
      return '';
    case 'ItemTag':
      return '';
    case 'Item':
      return ItemFilterFields1;
    case 'UserConfig':
      return UserConfigFilterFields1;
    case 'Feedback':
      return FeedbackFilterFields1;
    case 'Friend':
      return FriendFilterFields1;
    default:
      return '';
  }
};

export const getFilterFields2 = (entityName: string) => {
  switch (entityName) {
    case 'ShoppingList':
      return ShoppingListFilterFields2;
    case 'Unit':
      return '';
    case 'ItemTag':
      return '';
    case 'Item':
      return ItemFilterFields2;
    case 'UserConfig':
      return UserConfigFilterFields2;
    case 'Feedback':
      return FeedbackFilterFields2;
    case 'Friend':
      return FriendFilterFields2;
    default:
      return '';
  }
};
