import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../../store';
import { ReduxLoadingStatus, ReduxError } from '../../../interfaces/redux';
import { initialApiStatusState } from '../../data/const';
import {
  addFriend,
  updateFriend,
  getByFilterFriend,
  FriendState,
  getByIdFriend,
} from './friendActions';
import { Friend } from '../../../interfaces/backend';
import { sendFriendRequestByEmail } from './friendCustomActions';

export const initialState: FriendState = {
  data: {
    records: [],
    isInitialized: false,
  },
  commonApiStatus: {
    ...initialApiStatusState,
  },
  customApiStatus: {
    sendFriendRequestByEmailApiStatus: {
      loadingStatus: ReduxLoadingStatus.IDLE,
    },
  },
};

const updateData = (state: FriendState, record: Friend): Friend[] => [
  ...state.data.records.filter((sl) => sl.id !== record.id),
  record,
];

const entityName = 'Friend';
export const friendSlice = createSlice({
  name: entityName,
  initialState,
  reducers: {
    clearFriendData: () => initialState,
    clearFriendApiStatus: (state) => ({
      ...state,
      commonApiStatus: {
        ...initialApiStatusState,
      },
      customApiStatus: {
        ...initialState.customApiStatus,
      },
    }),
  },

  extraReducers: (builder) => {
    builder.addCase(getByIdFriend.pending, (state) => ({
      ...state,
      commonApiStatus: {
        ...state.commonApiStatus,
        getByIdApiStatus: {
          loadingStatus: ReduxLoadingStatus.LOADING,
        },
      },
    }));
    builder.addCase(getByIdFriend.fulfilled, (state, action) => ({
      ...state,
      data: { ...state.data, records: updateData(state, action.payload) },
      commonApiStatus: {
        ...state.commonApiStatus,
        getByIdApiStatus: {
          loadingStatus: ReduxLoadingStatus.COMPLETED,
        },
      },
    }));
    builder.addCase(getByIdFriend.rejected, (state, action) => ({
      ...state,
      commonApiStatus: {
        ...state.commonApiStatus,
        getByIdApiStatus: {
          loadingStatus: ReduxLoadingStatus.FAILED,
          error: action.payload ? action.payload : action.error,
          errorNote:
            (action?.payload as ReduxError)?.message ||
            `Error while fetching ${entityName} record`,
        },
      },
    }));
    builder.addCase(getByFilterFriend.pending, (state) => ({
      ...state,
      commonApiStatus: {
        ...state.commonApiStatus,
        getByFilterApiStatus: {
          loadingStatus: ReduxLoadingStatus.LOADING,
        },
      },
    }));
    builder.addCase(getByFilterFriend.fulfilled, (state, action) => ({
      ...state,
      data: { ...state.data, records: action.payload, isInitialized: true },
      commonApiStatus: {
        ...state.commonApiStatus,
        getByFilterApiStatus: {
          loadingStatus: ReduxLoadingStatus.COMPLETED,
        },
      },
    }));
    builder.addCase(getByFilterFriend.rejected, (state, action) => ({
      ...state,
      commonApiStatus: {
        ...state.commonApiStatus,
        getByFilterApiStatus: {
          loadingStatus: ReduxLoadingStatus.FAILED,
          error: action.payload ? action.payload : action.error,
          errorNote:
            (action?.payload as ReduxError)?.message ||
            `Error while fetching ${entityName} records`,
        },
      },
    }));
    builder.addCase(addFriend.pending, (state) => ({
      ...state,
      commonApiStatus: {
        ...state.commonApiStatus,
        addApiStatus: {
          loadingStatus: ReduxLoadingStatus.LOADING,
        },
      },
    }));
    builder.addCase(addFriend.fulfilled, (state, action) => ({
      ...state,
      data: { ...state.data, records: updateData(state, action.payload) },
      commonApiStatus: {
        ...state.commonApiStatus,
        addApiStatus: {
          loadingStatus: ReduxLoadingStatus.COMPLETED,
        },
      },
    }));
    builder.addCase(addFriend.rejected, (state, action) => ({
      ...state,
      commonApiStatus: {
        ...state.commonApiStatus,
        addApiStatus: {
          loadingStatus: ReduxLoadingStatus.FAILED,
          error: action.payload ? action.payload : action.error,
          errorNote:
            (action?.payload as ReduxError)?.message ||
            `Error while creating ${entityName} record`,
        },
      },
    }));
    builder.addCase(updateFriend.pending, (state) => ({
      ...state,
      commonApiStatus: {
        ...state.commonApiStatus,
        updateApiStatus: {
          loadingStatus: ReduxLoadingStatus.LOADING,
        },
      },
    }));
    builder.addCase(updateFriend.fulfilled, (state, action) => ({
      ...state,
      data: { ...state.data, records: updateData(state, action.payload) },
      commonApiStatus: {
        ...state.commonApiStatus,
        updateApiStatus: {
          loadingStatus: ReduxLoadingStatus.COMPLETED,
        },
      },
    }));
    builder.addCase(updateFriend.rejected, (state, action) => ({
      ...state,
      commonApiStatus: {
        ...state.commonApiStatus,
        updateApiStatus: {
          loadingStatus: ReduxLoadingStatus.FAILED,
          error: action.payload ? action.payload : action.error,
          errorNote:
            (action?.payload as ReduxError)?.message ||
            `Error while updating ${entityName} record`,
        },
      },
    }));

    builder.addCase(sendFriendRequestByEmail.pending, (state) => ({
      ...state,
      customApiStatus: {
        ...state.customApiStatus,
        sendFriendRequestByEmailApiStatus: {
          loadingStatus: ReduxLoadingStatus.LOADING,
        },
      },
    }));
    builder.addCase(sendFriendRequestByEmail.fulfilled, (state, action) => ({
      ...state,
      data: {
        ...state.data,
        records: action.payload.friend
          ? updateData(state, action.payload.friend)
          : state.data.records,
      },
      customApiStatus: {
        ...state.customApiStatus,
        sendFriendRequestByEmailApiStatus: {
          loadingStatus: ReduxLoadingStatus.COMPLETED,
          message: action.payload.message,
        },
      },
    }));
    builder.addCase(sendFriendRequestByEmail.rejected, (state, action) => ({
      ...state,
      customApiStatus: {
        ...state.customApiStatus,
        sendFriendRequestByEmailApiStatus: {
          loadingStatus: ReduxLoadingStatus.FAILED,
          error: action.payload ? action.payload : action.error,
          errorNote:
            (action?.payload as ReduxError)?.message ||
            `Error while sending friend request.`,
        },
      },
    }));
  },
});

export const { clearFriendData, clearFriendApiStatus } = friendSlice.actions;

export const getFriendStore = (state: RootState) => state.friendStore;

export * from './friendActions';

export default friendSlice.reducer;
