import React, { useCallback, useEffect } from 'react';
import { notification } from 'antd';

export enum NotificationType {
  SUCCESS = 'success',
  INFO = 'info',
  WARNING = 'warning',
  ERROR = 'error',
}

export interface NotifactionNote {
  type?: NotificationType;
  title?: string;
  message: string;
}

type Props = { note?: NotifactionNote; duration?: number };

const Notification = ({ note, duration }: Props) => {
  const [api, contextHolder] = notification.useNotification({ maxCount: 2 });

  const openNotificationWithIcon = useCallback(
    (pType: NotificationType, message: string, title?: string) => {
      api[pType]({
        message: title || pType.toUpperCase(),
        description: message,
        placement: 'bottomRight',
        duration: duration || 10,
      });
    },
    [api, duration]
  );

  useEffect(() => {
    if (note && note.message) {
      openNotificationWithIcon(
        note.type || NotificationType.ERROR,
        note.message,
        note.title
      );
    }
  }, [openNotificationWithIcon, note]);

  // eslint-disable-next-line
  return <>{contextHolder}</>;
};

export default Notification;
