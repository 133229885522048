import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../../store';
import { ReduxLoadingStatus, ReduxError } from '../../../interfaces/redux';
import { initialApiStatusState } from '../../data/const';
import {
  getAllUnit,
  addUnit,
  getByIdUnit,
  updateUnit,
  deleteByIdUnit,
  deleteByIdsUnit,
  getByFilterUnit,
  UnitState,
} from './unitActions';
import { Unit } from '../../../interfaces/backend';
import { DeleteResponseStatus } from '../../../interfaces/api';
import { stringSorter } from '../../../utils/sorter';

export const initialState: UnitState = {
  data: {
    records: [],
  },
  commonApiStatus: {
    ...initialApiStatusState,
  },
};

const updateData = (state: UnitState, record: Unit): Unit[] => {
  if (state.data?.records?.length <= 0) {
    return [record];
  }
  return (
    state.data?.records.map((unit) =>
      unit.id === record.id ? record : unit
    ) || [record]
  );
};

const entityName = 'Unit';
export const unitSlice = createSlice({
  name: entityName,
  initialState,
  reducers: {
    clearUnitData: () => initialState,
    clearUnitApiStatus: (state) => ({
      ...state,
      commonApiStatus: {
        ...initialApiStatusState,
      },
    }),
  },

  extraReducers: (builder) => {
    builder.addCase(getAllUnit.pending, (state) => ({
      ...state,
      commonApiStatus: {
        ...state.commonApiStatus,
        getAllApiStatus: {
          loadingStatus: ReduxLoadingStatus.LOADING,
        },
      },
    }));
    builder.addCase(getAllUnit.fulfilled, (state, action) => ({
      ...state,
      data: {
        records: [
          ...action.payload.sort((a, b) => stringSorter(a.name, b.name)),
        ],
      },

      commonApiStatus: {
        ...state.commonApiStatus,
        getAllApiStatus: {
          loadingStatus: ReduxLoadingStatus.COMPLETED,
        },
      },
    }));
    builder.addCase(getAllUnit.rejected, (state, action) => ({
      ...state,
      commonApiStatus: {
        ...state.commonApiStatus,
        getAllApiStatus: {
          loadingStatus: ReduxLoadingStatus.FAILED,
          error: action.payload ? action.payload : action.error,
          errorNote:
            (action?.payload as ReduxError)?.message ||
            `Error while fetching ${entityName} records`,
        },
      },
    }));
    builder.addCase(getByIdUnit.pending, (state) => ({
      ...state,
      commonApiStatus: {
        ...state.commonApiStatus,
        getByIdApiStatus: {
          loadingStatus: ReduxLoadingStatus.LOADING,
        },
      },
    }));
    builder.addCase(getByIdUnit.fulfilled, (state, action) => ({
      ...state,
      data: { records: updateData(state, action.payload) },
      commonApiStatus: {
        ...state.commonApiStatus,
        getByIdApiStatus: {
          loadingStatus: ReduxLoadingStatus.COMPLETED,
        },
      },
    }));
    builder.addCase(getByIdUnit.rejected, (state, action) => ({
      ...state,
      commonApiStatus: {
        ...state.commonApiStatus,
        getByIdApiStatus: {
          loadingStatus: ReduxLoadingStatus.FAILED,
          error: action.payload ? action.payload : action.error,
          errorNote:
            (action?.payload as ReduxError)?.message ||
            `Error while fetching ${entityName} record`,
        },
      },
    }));
    builder.addCase(getByFilterUnit.pending, (state) => ({
      ...state,
      commonApiStatus: {
        ...state.commonApiStatus,
        getByFilterApiStatus: {
          loadingStatus: ReduxLoadingStatus.LOADING,
        },
      },
    }));
    builder.addCase(getByFilterUnit.fulfilled, (state, action) => ({
      ...state,
      data: { records: action.payload },
      commonApiStatus: {
        ...state.commonApiStatus,
        getByFilterApiStatus: {
          loadingStatus: ReduxLoadingStatus.COMPLETED,
        },
      },
    }));
    builder.addCase(getByFilterUnit.rejected, (state, action) => ({
      ...state,
      commonApiStatus: {
        ...state.commonApiStatus,
        getByFilterApiStatus: {
          loadingStatus: ReduxLoadingStatus.FAILED,
          error: action.payload ? action.payload : action.error,
          errorNote:
            (action?.payload as ReduxError)?.message ||
            `Error while fetching ${entityName} records`,
        },
      },
    }));
    builder.addCase(addUnit.pending, (state) => ({
      ...state,
      commonApiStatus: {
        ...state.commonApiStatus,
        addApiStatus: {
          loadingStatus: ReduxLoadingStatus.LOADING,
        },
      },
    }));
    builder.addCase(addUnit.fulfilled, (state) => ({
      ...state,
      commonApiStatus: {
        ...state.commonApiStatus,
        addApiStatus: {
          loadingStatus: ReduxLoadingStatus.COMPLETED,
        },
      },
    }));
    builder.addCase(addUnit.rejected, (state, action) => ({
      ...state,
      commonApiStatus: {
        ...state.commonApiStatus,
        addApiStatus: {
          loadingStatus: ReduxLoadingStatus.FAILED,
          error: action.payload ? action.payload : action.error,
          errorNote:
            (action?.payload as ReduxError)?.message ||
            `Error while creating ${entityName} record`,
        },
      },
    }));
    builder.addCase(updateUnit.pending, (state) => ({
      ...state,
      commonApiStatus: {
        ...state.commonApiStatus,
        updateApiStatus: {
          loadingStatus: ReduxLoadingStatus.LOADING,
        },
      },
    }));
    builder.addCase(updateUnit.fulfilled, (state, action) => ({
      ...state,
      data: { records: updateData(state, action.payload) },
      commonApiStatus: {
        ...state.commonApiStatus,
        updateApiStatus: {
          loadingStatus: ReduxLoadingStatus.COMPLETED,
        },
      },
    }));
    builder.addCase(updateUnit.rejected, (state, action) => ({
      ...state,
      commonApiStatus: {
        ...state.commonApiStatus,
        updateApiStatus: {
          loadingStatus: ReduxLoadingStatus.FAILED,
          error: action.payload ? action.payload : action.error,
          errorNote:
            (action?.payload as ReduxError)?.message ||
            `Error while updating ${entityName} record`,
        },
      },
    }));
    builder.addCase(deleteByIdUnit.pending, (state) => ({
      ...state,
      commonApiStatus: {
        ...state.commonApiStatus,
        deleteByIdApiStatus: {
          loadingStatus: ReduxLoadingStatus.LOADING,
        },
      },
    }));
    builder.addCase(deleteByIdUnit.fulfilled, (state, action) => ({
      ...state,
      data: {
        records:
          state.data?.records.filter((unit) => unit.id !== action.payload) ||
          [],
      },
      commonApiStatus: {
        ...state.commonApiStatus,
        deleteByIdApiStatus: {
          loadingStatus: ReduxLoadingStatus.COMPLETED,
        },
      },
    }));
    builder.addCase(deleteByIdUnit.rejected, (state, action) => ({
      ...state,
      commonApiStatus: {
        ...state.commonApiStatus,
        deleteByIdApiStatus: {
          loadingStatus: ReduxLoadingStatus.FAILED,
          error: action.payload ? action.payload : action.error,
          errorNote:
            (action?.payload as ReduxError)?.message ||
            `Error while deleting ${entityName} record`,
        },
      },
    }));
    builder.addCase(deleteByIdsUnit.pending, (state) => ({
      ...state,
      commonApiStatus: {
        ...state.commonApiStatus,
        deleteApiStatus: {
          loadingStatus: ReduxLoadingStatus.LOADING,
        },
      },
    }));
    builder.addCase(deleteByIdsUnit.fulfilled, (state, action) => {
      const deletedIds = action.payload
        .filter((unit) => unit.status === DeleteResponseStatus.PASS)
        .map((unit) => unit.id);
      const failedIds = action.payload
        .filter((unit) => unit.status === DeleteResponseStatus.FAIL)
        .map((unit) => unit.id);
      return {
        ...state,
        data: {
          records:
            state.data?.records.filter(
              (unit) => !deletedIds.includes(unit.id)
            ) || [],
        },
        commonApiStatus: {
          ...state.commonApiStatus,
          deleteApiStatus: {
            loadingStatus: ReduxLoadingStatus.COMPLETED,
            error: action.payload,
            errorNote: failedIds.length
              ? `Error while deleting few ${entityName} records`
              : undefined,
          },
        },
      };
    });
    builder.addCase(deleteByIdsUnit.rejected, (state, action) => ({
      ...state,
      commonApiStatus: {
        ...state.commonApiStatus,
        deleteApiStatus: {
          loadingStatus: ReduxLoadingStatus.FAILED,
          error: action.payload ? action.payload : action.error,
          errorNote:
            (action?.payload as ReduxError)?.message ||
            `Error while deleting ${entityName} records`,
        },
      },
    }));
  },
});

export const { clearUnitData, clearUnitApiStatus } = unitSlice.actions;

export const getUnitStore = (state: RootState) => state.unitStore;

export * from './unitActions';

export default unitSlice.reducer;
