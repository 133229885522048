import { createAsyncThunk } from '@reduxjs/toolkit';

import { ReduxError } from '../../../interfaces/redux';
import { getErrorMessage } from '../../../utils/api';
import {
  resetShoppingListApi,
  updateItemsOrderApi,
} from '../../../apis/customApisGraph';

export const updateItemsOrder = createAsyncThunk<
  { shoppingListId: string; ids: string[] } | undefined,
  { shoppingListId: string; ids: string[] }
>(
  `item/updateItemsOrderApi`,
  async ({ shoppingListId, ids }, { rejectWithValue }) => {
    try {
      const result = await updateItemsOrderApi(shoppingListId, ids);
      if (result) {
        return { shoppingListId, ids };
      }
      return undefined;
    } catch (err) {
      return rejectWithValue({
        error: JSON.stringify(err),
        message: getErrorMessage(err),
        requestPayload: { ids },
        url: `/updateItemsOrderApi`,
      } as ReduxError);
    }
  }
);

export const resetShoppingList = createAsyncThunk<string | undefined, string>(
  `item/resetShoppingListApi`,
  async (shoppingListId, { rejectWithValue }) => {
    try {
      const result = await resetShoppingListApi(shoppingListId);
      if (result) {
        return shoppingListId;
      }
      return undefined;
    } catch (err) {
      return rejectWithValue({
        error: JSON.stringify(err),
        message: getErrorMessage(err),
        requestPayload: { shoppingListId },
        url: `/resetShoppingListApi`,
      } as ReduxError);
    }
  }
);
