import { CSRF_TOKEN_ERROR, CSRF_TOKEN_ERROR_MESSAGE } from './constants';
import { removeCsrfToken } from './csrftoken';

export const getErrorMessage = (err: any) => {
  // if error is of type graphql error then either return message from result or return undefined
  if (typeof err === 'object' && (err.networkError || err.graphQLErrors)) {
    if (err.networkError?.result?.message === CSRF_TOKEN_ERROR) {
      removeCsrfToken();
      return CSRF_TOKEN_ERROR_MESSAGE;
    }
    if (err.networkError?.result?.message) {
      return err.networkError.result.message;
    }
    if (err.graphQLErrors?.length) {
      return err.graphQLErrors[0].message;
    }
    return undefined;
  }
  // for any other error return error message (axios)
  if (err.message === CSRF_TOKEN_ERROR) {
    removeCsrfToken();
    return CSRF_TOKEN_ERROR_MESSAGE;
  }
  return err.message;
};

export const getErrorMessageString = (err: any) => {
  const error = getErrorMessage(err);
  if (typeof error === 'string') {
    return error;
  }
  return JSON.stringify(error);
};
