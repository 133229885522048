import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  addApi,
  updateApi,
  getByFilterApi,
  getByIdApi,
} from '../../../apis/commonApis';
import { Friend } from '../../../interfaces/backend';
import {
  ReduxApiStatus,
  ReduxCommonApisStatus,
  ReduxError,
} from '../../../interfaces/redux';
import { getErrorMessage } from '../../../utils/api';
import { FormatTextType, formatText } from '../../../utils/common';

export interface FriendState {
  data: { records: Friend[]; isInitialized: boolean };
  commonApiStatus: ReduxCommonApisStatus;
  customApiStatus: {
    sendFriendRequestByEmailApiStatus: ReduxApiStatus;
  };
}

const entityName = 'Friend';
const entityNameLowerCase = formatText(entityName, FormatTextType.LOWER);

export const getByIdFriend = createAsyncThunk<
  Friend,
  { id: string; hardRefresh?: boolean }
>(
  `${entityNameLowerCase}/getById`,
  async ({ id, hardRefresh }, { rejectWithValue, getState }) => {
    try {
      if (!hardRefresh) {
        const friendStore = (getState() as any).friendStore as FriendState;
        const record = friendStore.data.records?.find((x) => x.id === id);
        if (record) {
          return { ...record };
        }
      }
      return await getByIdApi(entityName, id);
    } catch (err) {
      return rejectWithValue({
        error: JSON.stringify(err),
        message: getErrorMessage(err),
        requestPayload: { id },
        url: `${entityNameLowerCase}/getById`,
      } as ReduxError);
    }
  }
);

export const getByFilterFriend = createAsyncThunk<
  Friend[],
  { filter: Partial<Friend>; hardRefresh?: boolean }
>(
  `${entityNameLowerCase}/getByFIlter`,
  async ({ filter: record, hardRefresh }, { rejectWithValue, getState }) => {
    try {
      const friendStore = (getState() as any).friendStore as FriendState;
      if (friendStore.data.isInitialized && !hardRefresh) {
        return [...friendStore.data.records];
      }
      return await getByFilterApi(entityName, record);
    } catch (err) {
      return rejectWithValue({
        error: JSON.stringify(err),
        message: getErrorMessage(err),
        requestPayload: { record },
        url: `${entityNameLowerCase}/getByFIlter`,
      } as ReduxError);
    }
  }
);

export const addFriend = createAsyncThunk<Friend, Partial<Friend>>(
  `${entityNameLowerCase}/add`,
  async (record: Partial<Friend>, { rejectWithValue }) => {
    try {
      return await addApi(entityName, record);
    } catch (err) {
      return rejectWithValue({
        error: JSON.stringify(err),
        message: getErrorMessage(err),
        requestPayload: { record },
        url: `${entityNameLowerCase}/add`,
      } as ReduxError);
    }
  }
);

export const updateFriend = createAsyncThunk<
  Friend,
  { id: string; record: Partial<Friend> }
>(
  `${entityNameLowerCase}/update`,
  async ({ id, record }, { rejectWithValue }) => {
    try {
      return await updateApi(entityName, id, record);
    } catch (err) {
      return rejectWithValue({
        error: JSON.stringify(err),
        message: getErrorMessage(err),
        requestPayload: { id, record },
        url: `${entityNameLowerCase}/update`,
      } as ReduxError);
    }
  }
);
