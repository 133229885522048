import { gql } from 'apollo-boost';
import { v4 as uuidv4 } from 'uuid';

import { DeleteResponse } from '../interfaces/api';
import {
  getAddFields1,
  getAddFields2,
  getFilterFields1,
  getFilterFields2,
  getQueryFields,
  getUpdateFields1,
  getUpdateFields2,
} from '../utils/graphqlFields';
import { client } from '../utils/graphqlclient';
import { gtagActionResult } from '../utils/gtag';
import { getErrorMessageString } from '../utils/api';
import { removeTypename } from '../utils/common';

export const getAllApi = async <T>(entityName: string): Promise<T[]> => {
  const requestToken = uuidv4();
  gtagActionResult(`${entityName}_get_all`, requestToken);
  const GET_ALL = gql`
    query getAll${entityName} {
      getAll${entityName} {
        ${getQueryFields(entityName)}
      }
    }
  `;
  const res = await client
    .query({
      query: GET_ALL,
      fetchPolicy: 'network-only',
      context: {
        headers: {
          'request-token': requestToken,
        },
      },
    })
    .then((response) => {
      gtagActionResult(`${entityName}_get_all_result`, requestToken, 'success');
      return response.data[`getAll${entityName}`] as T[];
    })
    .catch((e) => {
      gtagActionResult(
        `${entityName}_get_all_result`,
        requestToken,
        getErrorMessageString(e)
      );
      throw e;
    });
  return res;
};

export const getByIdApi = async <T>(
  entityName: string,
  id: string
): Promise<T> => {
  const requestToken = uuidv4();
  gtagActionResult(`${entityName}_get_by_id`, requestToken, id);
  const GET_ENTITY_BY_ID = gql`
    query getById${entityName}($id: String!) {
      getById${entityName}(id: $id) {
        ${getQueryFields(entityName)}
      }
    }
  `;
  const res = await client
    .query({
      query: GET_ENTITY_BY_ID,
      variables: { id },
      fetchPolicy: 'network-only',
      context: {
        headers: {
          'request-token': requestToken,
        },
      },
    })
    .then((response) => {
      gtagActionResult(
        `${entityName}_get_by_id_result`,
        requestToken,
        'success'
      );
      return response.data[`getById${entityName}`] as T;
    })
    .catch((e) => {
      gtagActionResult(
        `${entityName}_get_by_id_result`,
        requestToken,
        getErrorMessageString(e)
      );
      throw e;
    });
  return res;
};

export const getByFilterApi = async <T>(
  entityName: string,
  record: Partial<T>
): Promise<T[]> => {
  const requestToken = uuidv4();
  gtagActionResult(
    `${entityName}_get_by_filter`,
    requestToken,
    JSON.stringify(record)
  );
  const FILTER_ENTITY = gql`
  query getByFilter${entityName} (${getFilterFields1(entityName)}) {
    getByFilter${entityName} (${getFilterFields2(entityName)}) {
        ${getQueryFields(entityName)}
      }
    }
  `;
  const res = await client
    .mutate({
      mutation: FILTER_ENTITY,
      variables: { ...record },
      fetchPolicy: 'no-cache',
      context: {
        headers: {
          'request-token': requestToken,
        },
      },
    })
    .then((response) => {
      gtagActionResult(
        `${entityName}_get_by_filter_result`,
        requestToken,
        'success'
      );
      return response.data[`getByFilter${entityName}`] as T[];
    })
    .catch((e) => {
      gtagActionResult(
        `${entityName}_get_by_filter_result`,
        requestToken,
        getErrorMessageString(e)
      );
      throw e;
    });
  return res;
};

export const addApi = async <T>(
  entityName: string,
  record: Partial<T>
): Promise<T> => {
  const requestToken = uuidv4();
  gtagActionResult(`${entityName}_add`, requestToken);
  const ADD_ENTITY = gql`
    mutation create${entityName} (${getAddFields1(entityName)}) {
      create${entityName} (${getAddFields2(entityName)}) {
        ${getQueryFields(entityName)}
      }
    }
  `;
  const res = await client
    .mutate({
      mutation: ADD_ENTITY,
      variables: { ...removeTypename(record) },
      fetchPolicy: 'no-cache',
      context: {
        headers: {
          'request-token': requestToken,
        },
      },
    })
    .then((response) => {
      gtagActionResult(`${entityName}_add_result`, requestToken, 'success');
      return response.data[`create${entityName}`] as T;
    })
    .catch((e) => {
      gtagActionResult(
        `${entityName}_add_result`,
        requestToken,
        getErrorMessageString(e)
      );
      throw e;
    });
  return res;
};

export const updateApi = async <T>(
  entityName: string,
  id: string,
  record: Partial<T>
): Promise<T> => {
  const requestToken = uuidv4();
  gtagActionResult(`${entityName}_update`, requestToken, id);
  const UPDATE_ENTITY = gql`
    mutation update${entityName} (${getUpdateFields1(entityName)}) {
      update${entityName} (${getUpdateFields2(entityName)}) {
        ${getQueryFields(entityName)}
      }
    }
  `;

  const res = await client
    .mutate({
      mutation: UPDATE_ENTITY,
      variables: { id, ...removeTypename(record) },
      fetchPolicy: 'no-cache',
      context: {
        headers: {
          'request-token': requestToken,
        },
      },
    })
    .then((response) => {
      gtagActionResult(`${entityName}_update_result`, requestToken, 'success');
      return response.data[`update${entityName}`] as T;
    })
    .catch((e) => {
      gtagActionResult(
        `${entityName}_update_result`,
        requestToken,
        getErrorMessageString(e)
      );
      throw e;
    });
  return res;
};

export const deleteByIdApi = async (
  entityName: string,
  id: string
): Promise<string> => {
  const requestToken = uuidv4();
  gtagActionResult(`${entityName}_delete_by_id`, requestToken, id);
  const DELETE_ENTITY = gql`
    mutation deleteById${entityName} ($id: String!) {
      deleteById${entityName} (id: $id) 
    }
  `;

  const res = await client
    .mutate({
      mutation: DELETE_ENTITY,
      variables: { id },
      fetchPolicy: 'no-cache',
      context: {
        headers: {
          'request-token': requestToken,
        },
      },
    })
    .then((response) => {
      gtagActionResult(
        `${entityName}_delete_by_id_result`,
        requestToken,
        'success'
      );
      return response.data[`deleteById${entityName}`] as string;
    })
    .catch((e) => {
      gtagActionResult(
        `${entityName}_delete_by_id_result`,
        requestToken,
        getErrorMessageString(e)
      );
      throw e;
    });
  return res;
};

export const deleteByIdsApi = async (
  entityName: string,
  ids: string[]
): Promise<DeleteResponse[]> => {
  const requestToken = uuidv4();
  gtagActionResult(`${entityName}_delete_by_ids`, requestToken, ids.join(','));
  const DELETE_ENTITY = gql`
  mutation deleteByIds${entityName} ($ids: [String!]!) {
    deleteByIds${entityName} (ids: $ids)  {
      id
      status
      message
    }
  }
`;

  const res = await client
    .mutate({
      mutation: DELETE_ENTITY,
      variables: { ids },
      fetchPolicy: 'no-cache',
      context: {
        headers: {
          'request-token': requestToken,
        },
      },
    })
    .then((response) => {
      gtagActionResult(
        `${entityName}_delete_by_ids_result`,
        requestToken,
        'success'
      );
      return response.data[`deleteByIds${entityName}`] as DeleteResponse[];
    })
    .catch((e) => {
      gtagActionResult(
        `${entityName}_delete_by_ids_result`,
        requestToken,
        getErrorMessageString(e)
      );
      throw e;
    });
  return res;
};
