import {
  ReduxLoadingStatus,
  ReduxCommonApisStatus,
} from '../../interfaces/redux';

export const initialApiStatusState: ReduxCommonApisStatus = {
  getAllApiStatus: {
    loadingStatus: ReduxLoadingStatus.IDLE,
  },
  getByIdApiStatus: {
    loadingStatus: ReduxLoadingStatus.IDLE,
  },
  getByFilterApiStatus: {
    loadingStatus: ReduxLoadingStatus.IDLE,
  },
  addApiStatus: {
    loadingStatus: ReduxLoadingStatus.IDLE,
  },
  updateApiStatus: {
    loadingStatus: ReduxLoadingStatus.IDLE,
  },
  deleteByIdApiStatus: {
    loadingStatus: ReduxLoadingStatus.IDLE,
  },
  deleteApiStatus: {
    loadingStatus: ReduxLoadingStatus.IDLE,
  },
};
