import { ApolloClient, InMemoryCache } from 'apollo-boost';
import { onError } from 'apollo-link-error';
import { HttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { getOrSetCsrfToken } from './csrftoken';
import AppConfig from '../AppConfig';
import { TIME_OUT } from './constants';
import { getUserToken } from './requetToken';
import { getOrSetTabId } from './tabId';

const apolloCache = new InMemoryCache();

const httpLink = new HttpLink({
  uri: `${AppConfig.apiEnv}/graphql`,
  headers: {
    'keep-alive': 'true',
  },
  fetchOptions: {
    credentials: 'include',
    timeout: TIME_OUT,
  },
});

const authLink = setContext(async (_, { headers }) => {
  const csrfTokenVal = await getOrSetCsrfToken();
  const tabId = getOrSetTabId();
  return {
    headers: {
      ...headers,
      'x-tab-id': tabId,
      'X-CSRF-Token': csrfTokenVal,
      'user-token': getUserToken(),
    },
  };
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  const logError = AppConfig.debugGraphql;
  if (graphQLErrors)
    graphQLErrors.forEach((graphQLError) => {
      if (logError)
        console.log(`[GraphQL error]: ${JSON.stringify(graphQLError)}`);
    });
  if (networkError) {
    if (logError)
      console.log(`[Network error]: ${JSON.stringify(networkError)}`);
    if ('statusCode' in networkError) {
      const { statusCode } = networkError;
      if (logError)
        console.log(
          `[Network error statusCode]: ${JSON.stringify(statusCode)}`
        );
      if (statusCode === 401) {
        window.location.href = '/errorlogout';
      }
    }
  }
});

const authFlowLink = authLink.concat(errorLink);

export const client = new ApolloClient({
  cache: apolloCache,
  link: authFlowLink.concat(httpLink),
});
