import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../../store';
import { ReduxLoadingStatus, ReduxError } from '../../../interfaces/redux';
import { initialApiStatusState } from '../../data/const';
import {
  getAllItem,
  addItem,
  getByIdItem,
  updateItem,
  deleteByIdItem,
  deleteByIdsItem,
  getByFilterItem,
  ItemState,
} from './itemActions';
import { DeleteResponseStatus } from '../../../interfaces/api';
import { resetShoppingList, updateItemsOrder } from './itemCustomActions';

export const initialState: ItemState = {
  data: {
    records: [],
  },
  commonApiStatus: {
    ...initialApiStatusState,
  },
  customApiStatus: {
    updateOrderApiStatus: {
      loadingStatus: ReduxLoadingStatus.IDLE,
    },
    resetShoppingListApiStatus: {
      loadingStatus: ReduxLoadingStatus.IDLE,
    },
  },
};

// const updateData = (state: ItemState, record: Item): Item[] => {
//   if (state.data?.records?.length <= 0) {
//     return [record];
//   }
//   return (
//     state.data?.records.map((item) =>
//       item.id === record.id ? record : item
//     ) || [record]
//   );
// };

const entityName = 'Item';
export const itemSlice = createSlice({
  name: entityName,
  initialState,
  reducers: {
    clearItemData: () => initialState,
    clearItemApiStatus: (state) => ({
      ...state,
      commonApiStatus: {
        ...initialApiStatusState,
      },
      customApiStatus: {
        ...initialState.customApiStatus,
      },
    }),
  },

  extraReducers: (builder) => {
    builder.addCase(getAllItem.pending, (state) => ({
      ...state,
      commonApiStatus: {
        ...state.commonApiStatus,
        getAllApiStatus: {
          loadingStatus: ReduxLoadingStatus.LOADING,
        },
      },
    }));
    builder.addCase(getAllItem.fulfilled, (state) => ({
      ...state,
      // data: { records: action.payload },
      commonApiStatus: {
        ...state.commonApiStatus,
        getAllApiStatus: {
          loadingStatus: ReduxLoadingStatus.COMPLETED,
        },
      },
    }));
    builder.addCase(getAllItem.rejected, (state, action) => ({
      ...state,
      commonApiStatus: {
        ...state.commonApiStatus,
        getAllApiStatus: {
          loadingStatus: ReduxLoadingStatus.FAILED,
          error: action.payload ? action.payload : action.error,
          errorNote:
            (action?.payload as ReduxError)?.message ||
            `Error while fetching ${entityName} records`,
        },
      },
    }));
    builder.addCase(getByIdItem.pending, (state) => ({
      ...state,
      commonApiStatus: {
        ...state.commonApiStatus,
        getByIdApiStatus: {
          loadingStatus: ReduxLoadingStatus.LOADING,
        },
      },
    }));
    builder.addCase(getByIdItem.fulfilled, (state) => ({
      ...state,
      // data: { records: updateData(state, action.payload) },
      commonApiStatus: {
        ...state.commonApiStatus,
        getByIdApiStatus: {
          loadingStatus: ReduxLoadingStatus.COMPLETED,
        },
      },
    }));
    builder.addCase(getByIdItem.rejected, (state, action) => ({
      ...state,
      commonApiStatus: {
        ...state.commonApiStatus,
        getByIdApiStatus: {
          loadingStatus: ReduxLoadingStatus.FAILED,
          error: action.payload ? action.payload : action.error,
          errorNote:
            (action?.payload as ReduxError)?.message ||
            `Error while fetching ${entityName} record`,
        },
      },
    }));
    builder.addCase(getByFilterItem.pending, (state) => ({
      ...state,
      commonApiStatus: {
        ...state.commonApiStatus,
        getByFilterApiStatus: {
          loadingStatus: ReduxLoadingStatus.LOADING,
        },
      },
    }));
    builder.addCase(getByFilterItem.fulfilled, (state) => ({
      ...state,
      // data: { records: action.payload },
      commonApiStatus: {
        ...state.commonApiStatus,
        getByFilterApiStatus: {
          loadingStatus: ReduxLoadingStatus.COMPLETED,
        },
      },
    }));
    builder.addCase(getByFilterItem.rejected, (state, action) => ({
      ...state,
      commonApiStatus: {
        ...state.commonApiStatus,
        getByFilterApiStatus: {
          loadingStatus: ReduxLoadingStatus.FAILED,
          error: action.payload ? action.payload : action.error,
          errorNote:
            (action?.payload as ReduxError)?.message ||
            `Error while fetching ${entityName} records`,
        },
      },
    }));
    builder.addCase(addItem.pending, (state) => ({
      ...state,
      commonApiStatus: {
        ...state.commonApiStatus,
        addApiStatus: {
          loadingStatus: ReduxLoadingStatus.LOADING,
        },
      },
    }));
    builder.addCase(addItem.fulfilled, (state) => ({
      ...state,
      commonApiStatus: {
        ...state.commonApiStatus,
        addApiStatus: {
          loadingStatus: ReduxLoadingStatus.COMPLETED,
        },
      },
    }));
    builder.addCase(addItem.rejected, (state, action) => ({
      ...state,
      commonApiStatus: {
        ...state.commonApiStatus,
        addApiStatus: {
          loadingStatus: ReduxLoadingStatus.FAILED,
          error: action.payload ? action.payload : action.error,
          errorNote:
            (action?.payload as ReduxError)?.message ||
            `Error while creating ${entityName} record`,
        },
      },
    }));
    builder.addCase(updateItem.pending, (state) => ({
      ...state,
      commonApiStatus: {
        ...state.commonApiStatus,
        updateApiStatus: {
          loadingStatus: ReduxLoadingStatus.LOADING,
        },
      },
    }));
    builder.addCase(updateItem.fulfilled, (state) => ({
      ...state,
      // data: { records: updateData(state, action.payload) },
      commonApiStatus: {
        ...state.commonApiStatus,
        updateApiStatus: {
          loadingStatus: ReduxLoadingStatus.COMPLETED,
        },
      },
    }));
    builder.addCase(updateItem.rejected, (state, action) => ({
      ...state,
      commonApiStatus: {
        ...state.commonApiStatus,
        updateApiStatus: {
          loadingStatus: ReduxLoadingStatus.FAILED,
          error: action.payload ? action.payload : action.error,
          errorNote:
            (action?.payload as ReduxError)?.message ||
            `Error while updating ${entityName} record`,
        },
      },
    }));
    builder.addCase(deleteByIdItem.pending, (state) => ({
      ...state,
      commonApiStatus: {
        ...state.commonApiStatus,
        deleteByIdApiStatus: {
          loadingStatus: ReduxLoadingStatus.LOADING,
        },
      },
    }));
    builder.addCase(deleteByIdItem.fulfilled, (state) => ({
      ...state,
      // data: {
      //   records:
      //     state.data?.records.filter((item) => item.id !== action.payload) ||
      //     [],
      // },
      commonApiStatus: {
        ...state.commonApiStatus,
        deleteByIdApiStatus: {
          loadingStatus: ReduxLoadingStatus.COMPLETED,
        },
      },
    }));
    builder.addCase(deleteByIdItem.rejected, (state, action) => ({
      ...state,
      commonApiStatus: {
        ...state.commonApiStatus,
        deleteByIdApiStatus: {
          loadingStatus: ReduxLoadingStatus.FAILED,
          error: action.payload ? action.payload : action.error,
          errorNote:
            (action?.payload as ReduxError)?.message ||
            `Error while deleting ${entityName} record`,
        },
      },
    }));
    builder.addCase(deleteByIdsItem.pending, (state) => ({
      ...state,
      commonApiStatus: {
        ...state.commonApiStatus,
        deleteApiStatus: {
          loadingStatus: ReduxLoadingStatus.LOADING,
        },
      },
    }));
    builder.addCase(deleteByIdsItem.fulfilled, (state, action) => {
      // const deletedIds = action.payload
      //   .filter((item) => item.status === DeleteResponseStatus.PASS)
      //   .map((item) => item.id);
      const failedIds = action.payload
        .filter((item) => item.status === DeleteResponseStatus.FAIL)
        .map((item) => item.id);
      return {
        ...state,
        // data: {
        //   records:
        //     state.data?.records.filter(
        //       (item) => !deletedIds.includes(item.id)
        //     ) || [],
        // },
        commonApiStatus: {
          ...state.commonApiStatus,
          deleteApiStatus: {
            loadingStatus: ReduxLoadingStatus.COMPLETED,
            error: action.payload,
            errorNote: failedIds.length
              ? `Error while deleting few ${entityName} records`
              : undefined,
          },
        },
      };
    });
    builder.addCase(deleteByIdsItem.rejected, (state, action) => ({
      ...state,
      commonApiStatus: {
        ...state.commonApiStatus,
        deleteApiStatus: {
          loadingStatus: ReduxLoadingStatus.FAILED,
          error: action.payload ? action.payload : action.error,
          errorNote:
            (action?.payload as ReduxError)?.message ||
            `Error while deleting ${entityName} records`,
        },
      },
    }));

    builder.addCase(updateItemsOrder.pending, (state) => ({
      ...state,
      customApiStatus: {
        ...state.customApiStatus,
        updateOrderApiStatus: {
          loadingStatus: ReduxLoadingStatus.LOADING,
        },
      },
    }));
    builder.addCase(updateItemsOrder.fulfilled, (state) => ({
      ...state,
      // data: {
      //   ...state.data,
      //   records: state.data?.records.map((list) => {
      //     const index = action.payload.indexOf(list.id);
      //     return {
      //       ...list,
      //       order: index,
      //     };
      //   }),
      // },
      customApiStatus: {
        ...state.customApiStatus,
        updateOrderApiStatus: {
          loadingStatus: ReduxLoadingStatus.COMPLETED,
        },
      },
    }));
    builder.addCase(updateItemsOrder.rejected, (state, action) => ({
      ...state,
      customApiStatus: {
        ...state.customApiStatus,
        updateOrderApiStatus: {
          loadingStatus: ReduxLoadingStatus.FAILED,
          error: action.payload ? action.payload : action.error,
          errorNote:
            (action?.payload as ReduxError)?.message ||
            `Error while reordering ${entityName} records`,
        },
      },
    }));

    builder.addCase(resetShoppingList.pending, (state) => ({
      ...state,
      customApiStatus: {
        ...state.customApiStatus,
        resetShoppingListApiStatus: {
          loadingStatus: ReduxLoadingStatus.LOADING,
        },
      },
    }));
    builder.addCase(resetShoppingList.fulfilled, (state) => ({
      ...state,
      // data: {},
      customApiStatus: {
        ...state.customApiStatus,
        resetShoppingListApiStatus: {
          loadingStatus: ReduxLoadingStatus.COMPLETED,
        },
      },
    }));
    builder.addCase(resetShoppingList.rejected, (state, action) => ({
      ...state,
      customApiStatus: {
        ...state.customApiStatus,
        resetShoppingListApiStatus: {
          loadingStatus: ReduxLoadingStatus.FAILED,
          error: action.payload ? action.payload : action.error,
          errorNote:
            (action?.payload as ReduxError)?.message ||
            `Error while reseting ${entityName} records`,
        },
      },
    }));
  },
});

export const { clearItemData, clearItemApiStatus } = itemSlice.actions;

export const getItemStore = (state: RootState) => state.itemStore;

export * from './itemActions';

export default itemSlice.reducer;
