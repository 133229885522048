import { v4 as uuidv4 } from 'uuid';
import { getUserId, getUserToken, setUserId } from './requetToken';
import AppConfig from '../AppConfig';

export const gtagPageView = (pageName: string, extraInfo?: string) => {
  if (!AppConfig.sendAnalytics) return;
  const { gtag } = window as any;
  if (!gtag) return;
  gtag('event', 'page_view', {
    event_category: '',
    event_label: '',
    value: '',
    unique_id: uuidv4(),
    platform: localStorage.getItem('platform') || 'web',
    url: window.location.hostname,
    time: new Date().toISOString(),
    user_token: getUserToken(),
    user_id_known: getUserId(),
    info: pageName,
    extra_info: extraInfo,
  });
};

export const gtagUserVisitUnknown = (extraInfo?: string) => {
  if (!AppConfig.sendAnalytics) return;
  // Check if there's already an ID
  let browserId = localStorage.getItem('browserId');

  // If there's no ID, create a new one
  if (!browserId) {
    browserId = `id_${uuidv4()}`;
    localStorage.setItem('browserId', browserId);
  }

  const { gtag } = window as any;
  if (!gtag) return;
  gtag('event', 'user_visit_unknown', {
    event_category: '',
    event_label: '',
    value: '',
    unique_id: uuidv4(),
    platform: localStorage.getItem('platform') || 'web',
    url: window.location.hostname,
    time: new Date().toISOString(),
    user_token: getUserToken(),
    user_id_known: getUserId(),
    info: browserId,
    extra_info: extraInfo,
  });
};

export const gtagUserVisitKnown = (userId: string, extraInfo?: string) => {
  if (!AppConfig.sendAnalytics) return;
  setUserId(userId);
  const { gtag } = window as any;
  if (!gtag) return;
  gtag('event', 'user_visit_known', {
    event_category: '',
    event_label: '',
    value: '',
    unique_id: uuidv4(),
    platform: localStorage.getItem('platform') || 'web',
    url: window.location.hostname,
    time: new Date().toISOString(),
    user_token: getUserToken(),
    user_id_known: userId,
    info: userId,
    extra_info: extraInfo,
  });
};

export const gtagActionResult = (
  action: string,
  requestToken: string,
  extraInfo?: string
) => {
  if (!AppConfig.sendAnalytics) return;
  const { gtag } = window as any;
  if (!gtag) return;
  gtag('event', 'action_result', {
    event_category: '',
    event_label: '',
    value: '',
    unique_id: uuidv4(),
    platform: localStorage.getItem('platform') || 'web',
    url: window.location.hostname,
    time: new Date().toISOString(),
    user_token: getUserToken(),
    user_id_known: getUserId(),
    request_token: requestToken,
    info: action,
    extra_info: extraInfo,
  });
};
