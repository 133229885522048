import { v4 as uuidv4 } from 'uuid';

export const getUserToken = () => {
  let token = localStorage.getItem('userToken');
  if (!token) {
    token = uuidv4();
    localStorage.setItem('userToken', token);
  }
  return token;
};

export const setUserId = (userId: string) => {
  localStorage.setItem('userId', userId);
};

export const getUserId = () => localStorage.getItem('userId');
