import { v4 as uuidv4 } from 'uuid';

export const TAB_ID_KEY = 'tab_id';

export const getOrSetTabId = () => {
  const tabIdExisting = sessionStorage.getItem(TAB_ID_KEY);
  if (tabIdExisting) {
    return tabIdExisting;
  }
  const tabIdNew = uuidv4();
  sessionStorage.setItem(TAB_ID_KEY, tabIdNew);
  return tabIdNew;
};
