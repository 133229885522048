import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getAllApi,
  getByIdApi,
  addApi,
  updateApi,
  deleteByIdsApi,
  deleteByIdApi,
  getByFilterApi,
} from '../../../apis/commonApis';
import { Item } from '../../../interfaces/backend';
import { DeleteResponse } from '../../../interfaces/api';
import {
  ReduxApiStatus,
  ReduxCommonApisStatus,
  ReduxError,
} from '../../../interfaces/redux';
import { getErrorMessage } from '../../../utils/api';
import { FormatTextType, formatText } from '../../../utils/common';

export interface ItemState {
  data: { records: Item[] };
  commonApiStatus: ReduxCommonApisStatus;
  customApiStatus: {
    updateOrderApiStatus: ReduxApiStatus;
    resetShoppingListApiStatus: ReduxApiStatus;
  };
}
const entityName = 'Item';
const entityNameLowerCase = formatText(entityName, FormatTextType.LOWER);

export const getAllItem = createAsyncThunk<Item[]>(
  `${entityNameLowerCase}/getAll`,
  async (_, { rejectWithValue }) => {
    try {
      return await getAllApi(entityName);
    } catch (err) {
      return rejectWithValue({
        error: JSON.stringify(err),
        message: getErrorMessage(err),
        requestPayload: {},
        url: `${entityNameLowerCase}/getAll`,
      } as ReduxError);
    }
  }
);

export const getByIdItem = createAsyncThunk<Item, string>(
  `${entityNameLowerCase}/getById`,
  async (id: string, { rejectWithValue }) => {
    try {
      return await getByIdApi(entityName, id);
    } catch (err) {
      return rejectWithValue({
        error: JSON.stringify(err),
        message: getErrorMessage(err),
        requestPayload: { id },
        url: `${entityNameLowerCase}/getById`,
      } as ReduxError);
    }
  }
);

export const getByFilterItem = createAsyncThunk<Item[], Partial<Item>>(
  `${entityNameLowerCase}/getByFIlter`,
  async (record: Partial<Item>, { rejectWithValue }) => {
    try {
      return await getByFilterApi(entityName, record);
    } catch (err) {
      return rejectWithValue({
        error: JSON.stringify(err),
        message: getErrorMessage(err),
        requestPayload: { record },
        url: `${entityNameLowerCase}/getByFIlter`,
      } as ReduxError);
    }
  }
);

export const addItem = createAsyncThunk<Item, Partial<Item>>(
  `${entityNameLowerCase}/add`,
  async (record: Partial<Item>, { rejectWithValue }) => {
    try {
      return await addApi(entityName, record);
    } catch (err) {
      return rejectWithValue({
        error: JSON.stringify(err),
        message: getErrorMessage(err),
        requestPayload: { record },
        url: `${entityNameLowerCase}/add`,
      } as ReduxError);
    }
  }
);

export const updateItem = createAsyncThunk<
  Item,
  { id: string; record: Partial<Item> }
>(
  `${entityNameLowerCase}/update`,
  async ({ id, record }, { rejectWithValue }) => {
    try {
      return await updateApi(entityName, id, record);
    } catch (err) {
      return rejectWithValue({
        error: JSON.stringify(err),
        message: getErrorMessage(err),
        requestPayload: { id, record },
        url: `${entityNameLowerCase}/update`,
      } as ReduxError);
    }
  }
);

export const deleteByIdItem = createAsyncThunk<string, string>(
  `${entityNameLowerCase}/deleteById`,
  async (id: string, { rejectWithValue }) => {
    try {
      await deleteByIdApi(entityName, id);
      return id;
    } catch (err) {
      return rejectWithValue({
        error: JSON.stringify(err),
        message: getErrorMessage(err),
        requestPayload: { id },
        url: `${entityNameLowerCase}/deleteById`,
      } as ReduxError);
    }
  }
);

export const deleteByIdsItem = createAsyncThunk<DeleteResponse[], string[]>(
  `${entityNameLowerCase}/delete`,
  async (ids: string[], { rejectWithValue }) => {
    try {
      return await deleteByIdsApi(entityName, ids);
    } catch (err) {
      return rejectWithValue({
        error: JSON.stringify(err),
        message: getErrorMessage(err),
        requestPayload: { ids },
        url: `/delete`,
      } as ReduxError);
    }
  }
);
