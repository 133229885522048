export const wait = (ms: number) =>
  new Promise((res) => {
    setTimeout(res, ms);
  });

export enum FormatTextType {
  LOWER = 'LOWER',
  UPPER = 'UPPER',
  CAPITALIZE = 'CAPITALIZE',
}
export const formatText = (text: string, type: FormatTextType) => {
  switch (type) {
    case FormatTextType.LOWER:
      return text.toLowerCase();
    case FormatTextType.UPPER:
      return text.toUpperCase();
    case FormatTextType.CAPITALIZE:
      return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
    default:
      return text;
  }
};

export const generateUniqueName = (list: any[], name: string) => {
  const existingNames = list.map((item) => item.name.toLowerCase());
  let newName = name;
  let counter = 1;

  while (existingNames.includes(newName.toLowerCase())) {
    newName = `${name}${counter}`;
    counter += 1;
  }
  return newName;
};

// Helper function to recursively remove __typename
export const removeTypename = (value: any): any => {
  if (value === null || value === undefined) {
    return value;
  }
  if (Array.isArray(value)) {
    return value.map(removeTypename);
  }
  if (typeof value === 'object') {
    const newObj: any = {};
    Object.entries(value).forEach(([key, v]) => {
      if (key !== '__typename') {
        newObj[key] = removeTypename(v);
      }
    });
    return newObj;
  }
  return value;
};
