import { csrfTokenApi } from '../apis/csrfTokenApi';

export const TOKEN_KEY = 'csrftoken';

const addCsrfToken = (token: string) => {
  sessionStorage.setItem(TOKEN_KEY, token);
};

export const removeCsrfToken = () => {
  sessionStorage.removeItem(TOKEN_KEY);
};

export const getOrSetCsrfToken = async () => {
  const token = sessionStorage.getItem(TOKEN_KEY);
  if (token) {
    return token;
  }
  const csrfTokenVal = await csrfTokenApi();
  if (csrfTokenVal) {
    addCsrfToken(csrfTokenVal);
  } else {
    removeCsrfToken();
  }
  return csrfTokenVal;
};
