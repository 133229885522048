import { createAsyncThunk } from '@reduxjs/toolkit';
import { FriendRequestResponse } from '../../../interfaces/backend';
import { ReduxError } from '../../../interfaces/redux';
import { getErrorMessage } from '../../../utils/api';
import { FormatTextType, formatText } from '../../../utils/common';
import { sendFriendRequestByEmailApi } from '../../../apis/customApisGraph';

const entityName = 'Friend';
const entityNameLowerCase = formatText(entityName, FormatTextType.LOWER);

export const sendFriendRequestByEmail = createAsyncThunk<
  FriendRequestResponse,
  string
>(
  `${entityNameLowerCase}/sendFriendRequestByEmail`,
  async (email, { rejectWithValue }) => {
    try {
      const res = await sendFriendRequestByEmailApi(email);
      return res;
    } catch (err) {
      return rejectWithValue({
        error: JSON.stringify(err),
        message: getErrorMessage(err),
        requestPayload: { email },
        url: `${entityNameLowerCase}/sendFriendRequestByEmail`,
      } as ReduxError);
    }
  }
);
