import { createAsyncThunk } from '@reduxjs/toolkit';

import { ReduxError } from '../../../interfaces/redux';
import { getErrorMessage } from '../../../utils/api';
import {
  forgotPasswordApi,
  loginApi,
  loginStatusApi,
  logoutApi,
  oauthUserApi,
  recoverPasswordApi,
  signupApi,
  verifyUserApi,
} from '../../../apis/userApis';
import { User } from '../../../interfaces/user';

export const loginUser = createAsyncThunk<
  User,
  { email: string; password: string; recaptcha: string }
>(`user/login`, async ({ email, password, recaptcha }, { rejectWithValue }) => {
  try {
    return await loginApi(email, password, recaptcha);
  } catch (err: any) {
    return rejectWithValue({
      error: JSON.stringify(err),
      message: getErrorMessage(err),
      requestPayload: {},
      url: `user/login`,
    } as ReduxError);
  }
});

export const logoutUser = createAsyncThunk<boolean>(
  `user/logout`,
  async (_, { rejectWithValue }) => {
    try {
      return await logoutApi();
    } catch (err: any) {
      return rejectWithValue({
        error: JSON.stringify(err),
        message: getErrorMessage(err),
        requestPayload: {},
        url: `user/logout`,
      } as ReduxError);
    }
  }
);

export const loginStatus = createAsyncThunk<User>(
  `user/loginstatus`,
  async (_, { rejectWithValue }) => {
    try {
      return await loginStatusApi();
    } catch (err: any) {
      return rejectWithValue({
        error: JSON.stringify(err),
        message: getErrorMessage(err),
        requestPayload: {},
        url: `user/loginstatus`,
      } as ReduxError);
    }
  }
);

export const oauthUser = createAsyncThunk<User, string>(
  `user/oauthuser`,
  async (oauthToken, { rejectWithValue }) => {
    try {
      return await oauthUserApi(oauthToken);
    } catch (err: any) {
      return rejectWithValue({
        error: JSON.stringify(err),
        message: getErrorMessage(err),
        requestPayload: {},
        url: `user/oauthuser`,
      } as ReduxError);
    }
  }
);

export const forgotPassword = createAsyncThunk<
  boolean,
  { email: string; recaptcha: string }
>(`user/forgotpassword`, async ({ email, recaptcha }, { rejectWithValue }) => {
  try {
    return await forgotPasswordApi(email, recaptcha);
  } catch (err: any) {
    return rejectWithValue({
      error: JSON.stringify(err),
      message: getErrorMessage(err),
      requestPayload: {},
      url: `user/forgotpassword`,
    } as ReduxError);
  }
});

export const recoverPassword = createAsyncThunk<
  boolean,
  { token: string; password: string; recaptcha: string }
>(
  `user/recoverpassword`,
  async ({ token, password, recaptcha }, { rejectWithValue }) => {
    try {
      return await recoverPasswordApi(token, password, recaptcha);
    } catch (err: any) {
      return rejectWithValue({
        error: JSON.stringify(err),
        message: getErrorMessage(err),
        requestPayload: {},
        url: `user/recoverpassword`,
      } as ReduxError);
    }
  }
);

export const signupUser = createAsyncThunk<
  boolean,
  {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    password: string;
    recaptcha: string;
    addFriend: boolean;
    inviteId?: string;
  }
>(
  `user/signup`,
  async (
    {
      firstName,
      lastName,
      email,
      phone,
      password,
      recaptcha,
      addFriend,
      inviteId,
    },
    { rejectWithValue }
  ) => {
    try {
      return await signupApi(
        firstName,
        lastName,
        email,
        phone,
        password,
        recaptcha,
        addFriend,
        inviteId
      );
    } catch (err: any) {
      return rejectWithValue({
        error: JSON.stringify(err),
        message: getErrorMessage(err),
        requestPayload: {},
        url: `user/signup`,
      } as ReduxError);
    }
  }
);

export const verifyUser = createAsyncThunk<
  string,
  {
    token: string;
    recaptcha: string;
    verifyEmailToken?: string;
    verifyPhoneToken?: string;
  }
>(
  `user/verifyUser`,
  async (
    { token, recaptcha, verifyEmailToken, verifyPhoneToken },
    { rejectWithValue }
  ) => {
    try {
      return await verifyUserApi(
        token,
        recaptcha,
        verifyEmailToken,
        verifyPhoneToken
      );
    } catch (err: any) {
      return rejectWithValue({
        error: JSON.stringify(err),
        message: getErrorMessage(err),
        requestPayload: {},
        url: `user/verifyUser`,
      } as ReduxError);
    }
  }
);
