import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../../store';
import { ReduxLoadingStatus, ReduxError } from '../../../interfaces/redux';
import { initialApiStatusState } from '../../data/const';
import {
  getAllFeedback,
  addFeedback,
  getByIdFeedback,
  updateFeedback,
  deleteByIdFeedback,
  deleteByIdsFeedback,
  getByFilterFeedback,
  FeedbackState,
} from './feedbackActions';
import { Feedback } from '../../../interfaces/backend';
import { DeleteResponseStatus } from '../../../interfaces/api';

export const initialState: FeedbackState = {
  data: {
    records: [],
    isInitialized: false,
  },
  commonApiStatus: {
    ...initialApiStatusState,
  },
};

const updateData = (state: FeedbackState, record: Feedback): Feedback[] => [
  ...state.data.records.filter((sl) => sl.id !== record.id),
  record,
];

const entityName = 'Feedback';
export const feedbackSlice = createSlice({
  name: entityName,
  initialState,
  reducers: {
    clearFeedbackData: () => initialState,
    clearFeedbackApiStatus: (state) => ({
      ...state,
      commonApiStatus: {
        ...initialApiStatusState,
      },
    }),
  },

  extraReducers: (builder) => {
    builder.addCase(getAllFeedback.pending, (state) => ({
      ...state,
      commonApiStatus: {
        ...state.commonApiStatus,
        getAllApiStatus: {
          loadingStatus: ReduxLoadingStatus.LOADING,
        },
      },
    }));
    builder.addCase(getAllFeedback.fulfilled, (state, action) => ({
      ...state,
      data: { ...state.data, records: action.payload, isInitialized: true },
      commonApiStatus: {
        ...state.commonApiStatus,
        getAllApiStatus: {
          loadingStatus: ReduxLoadingStatus.COMPLETED,
        },
      },
    }));
    builder.addCase(getAllFeedback.rejected, (state, action) => ({
      ...state,
      commonApiStatus: {
        ...state.commonApiStatus,
        getAllApiStatus: {
          loadingStatus: ReduxLoadingStatus.FAILED,
          error: action.payload ? action.payload : action.error,
          errorNote:
            (action?.payload as ReduxError)?.message ||
            `Error while fetching ${entityName} records`,
        },
      },
    }));
    builder.addCase(getByIdFeedback.pending, (state) => ({
      ...state,
      commonApiStatus: {
        ...state.commonApiStatus,
        getByIdApiStatus: {
          loadingStatus: ReduxLoadingStatus.LOADING,
        },
      },
    }));
    builder.addCase(getByIdFeedback.fulfilled, (state, action) => ({
      ...state,
      data: {
        ...state.data,
        records: updateData(state, action.payload),
      },
      commonApiStatus: {
        ...state.commonApiStatus,
        getByIdApiStatus: {
          loadingStatus: ReduxLoadingStatus.COMPLETED,
        },
      },
    }));
    builder.addCase(getByIdFeedback.rejected, (state, action) => ({
      ...state,
      commonApiStatus: {
        ...state.commonApiStatus,
        getByIdApiStatus: {
          loadingStatus: ReduxLoadingStatus.FAILED,
          error: action.payload ? action.payload : action.error,
          errorNote:
            (action?.payload as ReduxError)?.message ||
            `Error while fetching ${entityName} record`,
        },
      },
    }));
    builder.addCase(getByFilterFeedback.pending, (state) => ({
      ...state,
      commonApiStatus: {
        ...state.commonApiStatus,
        getByFilterApiStatus: {
          loadingStatus: ReduxLoadingStatus.LOADING,
        },
      },
    }));
    builder.addCase(getByFilterFeedback.fulfilled, (state, action) => ({
      ...state,
      data: { ...state.data, records: action.payload, isInitialized: true },
      commonApiStatus: {
        ...state.commonApiStatus,
        getByFilterApiStatus: {
          loadingStatus: ReduxLoadingStatus.COMPLETED,
        },
      },
    }));
    builder.addCase(getByFilterFeedback.rejected, (state, action) => ({
      ...state,
      commonApiStatus: {
        ...state.commonApiStatus,
        getByFilterApiStatus: {
          loadingStatus: ReduxLoadingStatus.FAILED,
          error: action.payload ? action.payload : action.error,
          errorNote:
            (action?.payload as ReduxError)?.message ||
            `Error while fetching ${entityName} records`,
        },
      },
    }));
    builder.addCase(addFeedback.pending, (state) => ({
      ...state,
      commonApiStatus: {
        ...state.commonApiStatus,
        addApiStatus: {
          loadingStatus: ReduxLoadingStatus.LOADING,
        },
      },
    }));
    builder.addCase(addFeedback.fulfilled, (state, action) => ({
      ...state,
      data: { ...state.data, records: updateData(state, action.payload) },
      commonApiStatus: {
        ...state.commonApiStatus,
        addApiStatus: {
          loadingStatus: ReduxLoadingStatus.COMPLETED,
        },
      },
    }));
    builder.addCase(addFeedback.rejected, (state, action) => ({
      ...state,
      commonApiStatus: {
        ...state.commonApiStatus,
        addApiStatus: {
          loadingStatus: ReduxLoadingStatus.FAILED,
          error: action.payload ? action.payload : action.error,
          errorNote:
            (action?.payload as ReduxError)?.message ||
            `Error while creating ${entityName} record`,
        },
      },
    }));
    builder.addCase(updateFeedback.pending, (state) => ({
      ...state,
      commonApiStatus: {
        ...state.commonApiStatus,
        updateApiStatus: {
          loadingStatus: ReduxLoadingStatus.LOADING,
        },
      },
    }));
    builder.addCase(updateFeedback.fulfilled, (state, action) => ({
      ...state,
      data: { ...state.data, records: updateData(state, action.payload) },
      commonApiStatus: {
        ...state.commonApiStatus,
        updateApiStatus: {
          loadingStatus: ReduxLoadingStatus.COMPLETED,
        },
      },
    }));
    builder.addCase(updateFeedback.rejected, (state, action) => ({
      ...state,
      commonApiStatus: {
        ...state.commonApiStatus,
        updateApiStatus: {
          loadingStatus: ReduxLoadingStatus.FAILED,
          error: action.payload ? action.payload : action.error,
          errorNote:
            (action?.payload as ReduxError)?.message ||
            `Error while updating ${entityName} record`,
        },
      },
    }));
    builder.addCase(deleteByIdFeedback.pending, (state) => ({
      ...state,
      commonApiStatus: {
        ...state.commonApiStatus,
        deleteByIdApiStatus: {
          loadingStatus: ReduxLoadingStatus.LOADING,
        },
      },
    }));
    builder.addCase(deleteByIdFeedback.fulfilled, (state, action) => ({
      ...state,
      data: {
        ...state.data,
        records:
          state.data?.records.filter(
            (feedback) => feedback.id !== action.payload
          ) || [],
      },
      commonApiStatus: {
        ...state.commonApiStatus,
        deleteByIdApiStatus: {
          loadingStatus: ReduxLoadingStatus.COMPLETED,
        },
      },
    }));
    builder.addCase(deleteByIdFeedback.rejected, (state, action) => ({
      ...state,
      commonApiStatus: {
        ...state.commonApiStatus,
        deleteByIdApiStatus: {
          loadingStatus: ReduxLoadingStatus.FAILED,
          error: action.payload ? action.payload : action.error,
          errorNote:
            (action?.payload as ReduxError)?.message ||
            `Error while deleting ${entityName} record`,
        },
      },
    }));
    builder.addCase(deleteByIdsFeedback.pending, (state) => ({
      ...state,
      commonApiStatus: {
        ...state.commonApiStatus,
        deleteApiStatus: {
          loadingStatus: ReduxLoadingStatus.LOADING,
        },
      },
    }));
    builder.addCase(deleteByIdsFeedback.fulfilled, (state, action) => {
      const deletedIds = action.payload
        .filter((feedback) => feedback.status === DeleteResponseStatus.PASS)
        .map((feedback) => feedback.id);
      const failedIds = action.payload
        .filter((feedback) => feedback.status === DeleteResponseStatus.FAIL)
        .map((feedback) => feedback.id);
      return {
        ...state,
        data: {
          ...state.data,
          records:
            state.data?.records.filter(
              (feedback) => !deletedIds.includes(feedback.id)
            ) || [],
        },
        commonApiStatus: {
          ...state.commonApiStatus,
          deleteApiStatus: {
            loadingStatus: ReduxLoadingStatus.COMPLETED,
            error: action.payload,
            errorNote: failedIds.length
              ? `Error while deleting few ${entityName} records`
              : undefined,
          },
        },
      };
    });
    builder.addCase(deleteByIdsFeedback.rejected, (state, action) => ({
      ...state,
      commonApiStatus: {
        ...state.commonApiStatus,
        deleteApiStatus: {
          loadingStatus: ReduxLoadingStatus.FAILED,
          error: action.payload ? action.payload : action.error,
          errorNote:
            (action?.payload as ReduxError)?.message ||
            `Error while deleting ${entityName} records`,
        },
      },
    }));
  },
});

export const { clearFeedbackData, clearFeedbackApiStatus } =
  feedbackSlice.actions;

export const getFeedbackStore = (state: RootState) => state.feedbackStore;

export * from './feedbackActions';

export default feedbackSlice.reducer;
