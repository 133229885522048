import { createAsyncThunk } from '@reduxjs/toolkit';

import { ReduxError } from '../../../interfaces/redux';
import { getErrorMessage } from '../../../utils/api';
import {
  copyShoppingListApi,
  sampleShoppingListApi,
  updateShoppingListsOrderApi,
} from '../../../apis/customApisGraph';
import { ShoppingList } from '../../../interfaces/backend';

export const updateShoppingListsOrder = createAsyncThunk<
  string[] | undefined,
  { userId: number; ids: string[] }
>(
  `shoppinglist/updateShoppingListsOrderApi`,
  async ({ userId, ids }, { rejectWithValue }) => {
    try {
      const result = await updateShoppingListsOrderApi(userId, ids);
      if (result) {
        return ids;
      }
      return undefined;
    } catch (err) {
      return rejectWithValue({
        error: JSON.stringify(err),
        message: getErrorMessage(err),
        requestPayload: { ids },
        url: `/updateShoppingListsOrderApi`,
      } as ReduxError);
    }
  }
);

export const copyShoppingList = createAsyncThunk<
  ShoppingList,
  { id: string; name: string }
>(`shoppinglist/copy`, async ({ id, name }, { rejectWithValue }) => {
  try {
    return await copyShoppingListApi('ShoppingList', id, name);
  } catch (err) {
    return rejectWithValue({
      error: JSON.stringify(err),
      message: getErrorMessage(err),
      requestPayload: { id, name },
      url: `shoppinglist/copy`,
    } as ReduxError);
  }
});

export const sampleShoppingList = createAsyncThunk<ShoppingList>(
  `shoppinglist/sample`,
  async (_, { rejectWithValue }) => {
    try {
      return await sampleShoppingListApi();
    } catch (err) {
      return rejectWithValue({
        error: JSON.stringify(err),
        message: getErrorMessage(err),
        requestPayload: {},
        url: `shoppinglist/sample`,
      } as ReduxError);
    }
  }
);
