import React, { useEffect } from 'react';
import { Spin } from 'antd';

import './LoadingScreen.scss';
import { gtagPageView } from '../../utils/gtag';

type Props = {};

const LoadingScreen: React.FC<Props> = () => {
  useEffect(() => {
    gtagPageView('page_loading');
  }, []);

  return (
    <Spin spinning>
      <div className="loadingScreenContainer">Loading...</div>
    </Spin>
  );
};

export default LoadingScreen;
