import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../../store';
import {
  ReduxLoadingStatus,
  ReduxCommonApisStatus,
  ReduxError,
} from '../../../interfaces/redux';
import { initialApiStatusState } from '../../data/const';
import { updateUserConfig, getByFilterUserConfig } from './userConfigActions';
import { UserConfig } from '../../../interfaces/backend';

export interface UserConfigState {
  data: { records: UserConfig[] };
  commonApiStatus: ReduxCommonApisStatus;
}

export const initialState: UserConfigState = {
  data: {
    records: [],
  },
  commonApiStatus: {
    ...initialApiStatusState,
  },
};

const updateData = (
  state: UserConfigState,
  record: UserConfig
): UserConfig[] => {
  if (state.data?.records?.length <= 0) {
    return [record];
  }
  return (
    state.data?.records.map((userConfig) =>
      userConfig.id === record.id ? record : userConfig
    ) || [record]
  );
};

const entityName = 'UserConfig';
export const userConfigSlice = createSlice({
  name: entityName,
  initialState,
  reducers: {
    clearUserConfigData: () => initialState,
    clearUserConfigApiStatus: (state) => ({
      ...state,
      commonApiStatus: {
        ...initialApiStatusState,
      },
    }),
  },

  extraReducers: (builder) => {
    builder.addCase(getByFilterUserConfig.pending, (state) => ({
      ...state,
      commonApiStatus: {
        ...state.commonApiStatus,
        getByFilterApiStatus: {
          loadingStatus: ReduxLoadingStatus.LOADING,
        },
      },
    }));
    builder.addCase(getByFilterUserConfig.fulfilled, (state, action) => ({
      ...state,
      data: { records: action.payload },
      commonApiStatus: {
        ...state.commonApiStatus,
        getByFilterApiStatus: {
          loadingStatus: ReduxLoadingStatus.COMPLETED,
        },
      },
    }));
    builder.addCase(getByFilterUserConfig.rejected, (state, action) => ({
      ...state,
      commonApiStatus: {
        ...state.commonApiStatus,
        getByFilterApiStatus: {
          loadingStatus: ReduxLoadingStatus.FAILED,
          error: action.payload ? action.payload : action.error,
          errorNote:
            (action?.payload as ReduxError)?.message ||
            `Error while fetching user`,
        },
      },
    }));

    builder.addCase(updateUserConfig.pending, (state) => ({
      ...state,
      commonApiStatus: {
        ...state.commonApiStatus,
        updateApiStatus: {
          loadingStatus: ReduxLoadingStatus.LOADING,
        },
      },
    }));
    builder.addCase(updateUserConfig.fulfilled, (state, action) => ({
      ...state,
      data: { records: updateData(state, action.payload) },
      commonApiStatus: {
        ...state.commonApiStatus,
        updateApiStatus: {
          loadingStatus: ReduxLoadingStatus.COMPLETED,
        },
      },
    }));
    builder.addCase(updateUserConfig.rejected, (state, action) => ({
      ...state,
      commonApiStatus: {
        ...state.commonApiStatus,
        updateApiStatus: {
          loadingStatus: ReduxLoadingStatus.FAILED,
          error: action.payload ? action.payload : action.error,
          errorNote:
            (action?.payload as ReduxError)?.message ||
            `Error while updating user`,
        },
      },
    }));
  },
});

export const { clearUserConfigData, clearUserConfigApiStatus } =
  userConfigSlice.actions;

export const getUserConfigStore = (state: RootState) => state.userConfigStore;

export const getUserConfig = (state: RootState) =>
  state.userConfigStore.data.records?.[0];

export * from './userConfigActions';

export default userConfigSlice.reducer;
