import { configureStore } from '@reduxjs/toolkit';
import itemReducer, { clearItemData } from './reducers/item/itemSlice';
import userReducer from './reducers/user/userSlice';
import unitReducer, { clearUnitData } from './reducers/unit/unitSlice';
import userConfigReducer, {
  clearUserConfigData,
} from './reducers/userConfig/userConfigSlice';
import appReducer, { logoutAppData } from './reducers/app/appSlice';
import shoppingListReducer, {
  clearShoppingListData,
} from './reducers/shoppingList/shoppingListSlice';
import feedbackReducer, {
  clearFeedbackData,
} from './reducers/feedback/feedbackSlice';
import friendReducer, { clearFriendData } from './reducers/friend/friendSlice';

export const store = configureStore({
  reducer: {
    shoppingListStore: shoppingListReducer,
    itemStore: itemReducer,
    userStore: userReducer,
    unitStore: unitReducer,
    friendStore: friendReducer,
    feedbackStore: feedbackReducer,
    userConfigStore: userConfigReducer,
    appStore: appReducer,
  },
});

export const logoutStore = () => async (dispatch: AppDispatch) => {
  await Promise.all([
    dispatch(clearShoppingListData()),
    dispatch(clearItemData()),
    dispatch(clearUnitData()),
    dispatch(clearFriendData()),
    dispatch(clearFeedbackData()),
    dispatch(clearUserConfigData()),
    dispatch(logoutAppData()),
  ]);
};

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
