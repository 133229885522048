import axios from 'axios';
import { gql } from 'apollo-boost';
import { v4 as uuidv4 } from 'uuid';
import * as csrfToken from '../utils/csrftoken';
import { User, UserFull } from '../interfaces/user';
import AppConfig from '../AppConfig';
import { getAxiosError } from '../utils/axios';
import { TIME_OUT } from '../utils/constants';
import { client } from '../utils/graphqlclient';
import { gtagActionResult } from '../utils/gtag';
import { getErrorMessageString } from '../utils/api';
import { getUserToken } from '../utils/requetToken';
import { getOrSetTabId } from '../utils/tabId';

export const getHeaders = (csrfTokenVal?: string | null) => ({
  headers: {
    'X-CSRF-Token': csrfTokenVal,
    'x-tab-id': getOrSetTabId(),
    'request-token': uuidv4(),
    'user-token': getUserToken(),
  },
  timeout: TIME_OUT,
  withCredentials: true,
});

export const loginApi = async (
  email: string,
  password: string,
  recaptcha: string
): Promise<User> => {
  const csrfTokenVal = await csrfToken.getOrSetCsrfToken();
  const headers = getHeaders(csrfTokenVal);
  gtagActionResult(`login`, headers.headers['request-token'], undefined);
  const res = axios
    .post(
      `${AppConfig.apiEnv}/auth/login`,
      {
        username: email,
        password,
        recaptcha,
      },
      headers
    )
    .then((response) => {
      if (response.data.success && response.data.user) {
        gtagActionResult(
          `login_result`,
          headers.headers['request-token'],
          `success`
        );
        return response.data.user;
      }
      throw new Error(response.data.message);
    })
    .catch((err) => {
      gtagActionResult(
        `login_result`,
        headers.headers['request-token'],
        getErrorMessageString(err)
      );
      throw getAxiosError(err);
    });
  return res;
};

export const logoutApi = (): Promise<boolean> => {
  const headers = getHeaders();
  gtagActionResult(`logout`, headers.headers['request-token']);
  const res = axios
    .get(`${AppConfig.apiEnv}/auth/logout`, headers)
    .then((response) => {
      if (response.data.success && response.data.time) {
        gtagActionResult(
          `logout_result`,
          headers.headers['request-token'],
          `success`
        );
        return response.data.success;
      }
      throw new Error(response.data.message);
    })
    .catch((err) => {
      gtagActionResult(
        `logout_result`,
        headers.headers['request-token'],
        getErrorMessageString(err)
      );
      throw getAxiosError(err);
    });
  return res;
};

export const loginStatusApi = (): Promise<User> => {
  const headers = getHeaders();
  gtagActionResult(`login_status`, headers.headers['request-token']);
  const res = axios
    .get(`${AppConfig.apiEnv}/auth/status`, headers)
    .then((response) => {
      if (response.data.success && response.data.user) {
        gtagActionResult(
          `login_status_result`,
          headers.headers['request-token'],
          `success`
        );
        return response.data.user;
      }
      throw new Error(response.data.message);
    })
    .catch((err) => {
      gtagActionResult(
        `login_status_result`,
        headers.headers['request-token'],
        getErrorMessageString(err)
      );
      throw getAxiosError(err);
    });
  return res;
};

export const oauthUserApi = (oauthToken: string): Promise<User> => {
  const headers = getHeaders();
  (headers.headers as any)['oauth-token'] = oauthToken;
  gtagActionResult(`oauth_user`, headers.headers['request-token']);
  const res = axios
    .get(`${AppConfig.apiEnv}/auth/oauthUser`, headers)
    .then((response) => {
      if (response.data.success && response.data.user) {
        gtagActionResult(
          `oauth_user_result`,
          headers.headers['request-token'],
          `success`
        );
        return response.data.user;
      }
      throw new Error(response.data.message);
    })
    .catch((err) => {
      gtagActionResult(
        `oauth_user_result`,
        headers.headers['request-token'],
        getErrorMessageString(err)
      );
      throw getAxiosError(err);
    });
  return res;
};

export const forgotPasswordApi = async (
  email: string,
  recaptcha: string
): Promise<boolean> => {
  const csrfTokenVal = await csrfToken.getOrSetCsrfToken();
  const headers = getHeaders(csrfTokenVal);
  gtagActionResult(`forgot_password`, headers.headers['request-token']);
  const res = axios
    .post(
      `${AppConfig.apiEnv}/auth/forgot-password`,
      {
        email,
        recaptcha,
      },
      headers
    )
    .then((response) => {
      if (response.data.success) {
        gtagActionResult(
          `forgot_password_result`,
          headers.headers['request-token'],
          `success`
        );
        return response.data.success;
      }
      throw new Error(response.data.message);
    })
    .catch((err) => {
      gtagActionResult(
        `forgot_password_result`,
        headers.headers['request-token'],
        getErrorMessageString(err)
      );
      throw getAxiosError(err);
    });
  return res;
};

export const recoverPasswordApi = async (
  token: string,
  newPassword: string,
  recaptcha: string
): Promise<boolean> => {
  const csrfTokenVal = await csrfToken.getOrSetCsrfToken();
  const headers = getHeaders(csrfTokenVal);
  gtagActionResult(`recover_password`, headers.headers['request-token']);
  const res = axios
    .post(
      `${AppConfig.apiEnv}/auth/reset-password`,
      {
        token,
        newPassword,
        recaptcha,
      },
      headers
    )
    .then((response) => {
      if (response.data.success) {
        gtagActionResult(
          `recover_password_result`,
          headers.headers['request-token'],
          `success`
        );
        return response.data.success;
      }
      throw new Error(response.data.message);
    })
    .catch((err) => {
      gtagActionResult(
        `recover_password_result`,
        headers.headers['request-token'],
        getErrorMessageString(err)
      );
      throw getAxiosError(err);
    });
  return res;
};

export const signupApi = async (
  firstName: string,
  lastName: string,
  email: string,
  phone: string,
  password: string,
  recaptcha: string,
  addFriend: boolean,
  inviteId?: string
): Promise<boolean> => {
  const csrfTokenVal = await csrfToken.getOrSetCsrfToken();
  const headers = getHeaders(csrfTokenVal);
  gtagActionResult(`signup`, headers.headers['request-token']);
  const res = axios
    .post(
      `${AppConfig.apiEnv}/auth/signup`,
      {
        firstName,
        lastName,
        email,
        phone,
        password,
        recaptcha,
        addFriend,
        inviteId,
      },
      headers
    )
    .then((response) => {
      if (response.data.success) {
        gtagActionResult(
          `signup_result`,
          headers.headers['request-token'],
          `success`
        );
        return response.data.success;
      }
      throw new Error(response.data.message);
    })
    .catch((err) => {
      gtagActionResult(
        `signup_result`,
        headers.headers['request-token'],
        getErrorMessageString(err)
      );
      throw getAxiosError(err);
    });
  return res;
};

export const verifyUserApi = async (
  token: string,
  recaptcha: string,
  verifyEmailToken?: string,
  verifyPhoneToken?: string
): Promise<string> => {
  const csrfTokenVal = await csrfToken.getOrSetCsrfToken();
  const headers = getHeaders(csrfTokenVal);
  gtagActionResult(`verify_user`, headers.headers['request-token']);
  const res = axios
    .post(
      `${AppConfig.apiEnv}/auth/verify-user`,
      {
        token,
        recaptcha,
        verifyEmailToken,
        verifyPhoneToken,
      },
      headers
    )
    .then((response) => {
      if (response.data.success) {
        gtagActionResult(
          `verify_user_result`,
          headers.headers['request-token'],
          `success`
        );
        return response.data.message;
      }
      throw new Error(response.data.message);
    })
    .catch((err) => {
      gtagActionResult(
        `verify_user_result`,
        headers.headers['request-token'],
        getErrorMessageString(err)
      );
      throw getAxiosError(err);
    });
  return res;
};

// NOTE: Below API is not called using redux, like other apis in this file
// Don't need to keep senseitive data in redux
export const getUserByIdApi = async (id: number): Promise<UserFull> => {
  const requestToken = uuidv4();
  gtagActionResult(`user_get_by_id`, requestToken, id.toString());
  const GET_USER_BY_ID = gql`
    query getUserById($id: Int!) {
      getUserById(id: $id) {
        firstName
        lastName
        email
        phone
        createdAt
      }
    }
  `;
  const res = await client
    .query({
      query: GET_USER_BY_ID,
      variables: { id },
      fetchPolicy: 'network-only',
      context: {
        headers: {
          'request-token': requestToken,
        },
      },
    })
    .then((response) => {
      gtagActionResult(`user_get_by_id_result`, requestToken, `success`);
      return response.data.getUserById as UserFull;
    })
    .catch((e) => {
      gtagActionResult(
        `user_get_by_id_result`,
        requestToken,
        getErrorMessageString(e)
      );
      throw e;
    });
  return res;
};

// NOTE: Below API is not called using redux, like other apis in this file
// Don't need to keep senseitive data in redux
export const updateUserBasicsApi = async (
  id: number,
  name: string,
  phone: string
): Promise<UserFull> => {
  const requestToken = uuidv4();
  gtagActionResult(`user_update_basics`, requestToken, id.toString());
  const nameSplitted = name.split(' ');
  const firstName = nameSplitted[0];
  const lastName = nameSplitted.length > 1 ? nameSplitted[1] : '';
  const UPDATE_USER = gql`
    mutation updateUserBasics(
      $id: Int!
      $firstName: String
      $lastName: String
      $phone: String
    ) {
      updateUserBasics(
        id: $id
        firstName: $firstName
        lastName: $lastName
        phone: $phone
      ) {
        firstName
        lastName
        email
        phone
        createdAt
      }
    }
  `;
  const res = await client
    .mutate({
      mutation: UPDATE_USER,
      variables: { id, firstName, lastName, phone },
      fetchPolicy: 'no-cache',
      context: {
        headers: {
          'request-token': requestToken,
        },
      },
    })
    .then((response) => {
      gtagActionResult(`user_update_basics_result`, requestToken, `success`);
      return response.data.updateUserBasics as UserFull;
    })
    .catch((e) => {
      gtagActionResult(
        `user_update_basics_result`,
        requestToken,
        getErrorMessageString(e)
      );
      throw e;
    });
  return res;
};

// NOTE: Below API is not called using redux, like other apis in this file
// Don't need to keep senseitive data in redux
export const updateUserPasswordApi = async (
  id: number,
  currentPassword: string,
  newPassword: string
): Promise<UserFull> => {
  const requestToken = uuidv4();
  gtagActionResult(`user_update_password`, requestToken, id.toString());
  const UPDATE_USER = gql`
    mutation updateUserPassword(
      $id: Int!
      $currentPassword: String
      $newPassword: String
    ) {
      updateUserPassword(
        id: $id
        currentPassword: $currentPassword
        newPassword: $newPassword
      ) {
        firstName
        lastName
        email
        phone
        createdAt
      }
    }
  `;
  const res = await client
    .mutate({
      mutation: UPDATE_USER,
      variables: { id, currentPassword, newPassword },
      fetchPolicy: 'no-cache',
      context: {
        headers: {
          'request-token': requestToken,
        },
      },
    })
    .then((response) => {
      gtagActionResult(`user_update_password_result`, requestToken, `success`);
      return response.data.updateUserPassword as UserFull;
    })
    .catch((e) => {
      gtagActionResult(
        `user_update_password_result`,
        requestToken,
        getErrorMessageString(e)
      );
      throw e;
    });
  return res;
};

// NOTE: Below API is not called using redux, like other apis in this file
// Don't need to keep senseitive data in redux
export const deleteUserApi = async (id: number): Promise<UserFull> => {
  const requestToken = uuidv4();
  gtagActionResult(`user_delete`, requestToken, id.toString());
  const DELETE_USER = gql`
    mutation deleteUser($id: Int!) {
      deleteUser(id: $id) {
        firstName
        lastName
        email
        phone
        createdAt
      }
    }
  `;
  const res = await client
    .mutate({
      mutation: DELETE_USER,
      variables: { id },
      fetchPolicy: 'no-cache',
      context: {
        headers: {
          'request-token': requestToken,
        },
      },
    })
    .then((response) => {
      gtagActionResult(`user_delete_result`, requestToken, `success`);
      return response.data.deleteUser as UserFull;
    })
    .catch((e) => {
      gtagActionResult(
        `user_delete_result`,
        requestToken,
        getErrorMessageString(e)
      );
      throw e;
    });
  return res;
};

// NOTE: Below API is not called using redux, like other apis in this file
// There was no need of redux here as this is one time action
export const sendVerificationEmailApi = async (
  email: string
): Promise<boolean> => {
  const csrfTokenVal = await csrfToken.getOrSetCsrfToken();
  const headers = getHeaders(csrfTokenVal);
  gtagActionResult(
    `resend_verification_email`,
    headers.headers['request-token']
  );
  const res = axios
    .post(
      `${AppConfig.apiEnv}/auth/resend-verification-email`,
      {
        email,
      },
      headers
    )
    .then((response) => {
      if (response.data.success) {
        gtagActionResult(
          `resend_verification_email`,
          headers.headers['request-token'],
          `success`
        );
        return response.data.success;
      }
      throw new Error(response.data.message);
    })
    .catch((err) => {
      gtagActionResult(
        `resend_verification_email`,
        headers.headers['request-token'],
        getErrorMessageString(err)
      );
      throw getAxiosError(err);
    });
  return res;
};

// NOTE: Below API is not called using redux, like other apis in this file
// There was no need of redux here as this is one time action
export const unlockAccountApi = async (
  token: string,
  otp: string
): Promise<boolean> => {
  const csrfTokenVal = await csrfToken.getOrSetCsrfToken();
  const headers = getHeaders(csrfTokenVal);
  gtagActionResult(`unlock_account`, headers.headers['request-token']);
  const res = axios
    .post(
      `${AppConfig.apiEnv}/auth/unlock-account`,
      {
        token,
        otp,
      },
      headers
    )
    .then((response) => {
      if (response.data.success) {
        gtagActionResult(
          `unlock_account`,
          headers.headers['request-token'],
          `success`
        );
        return response.data.success;
      }
      throw new Error(response.data.message);
    })
    .catch((err) => {
      gtagActionResult(
        `unlock_account`,
        headers.headers['request-token'],
        getErrorMessageString(err)
      );
      throw getAxiosError(err);
    });
  return res;
};

// NOTE: Below API is not called using redux, like other apis in this file
// There was no need of redux here as this is one time action
export const checkAccountApi = async (
  email: string,
  recaptcha: string
): Promise<boolean> => {
  const csrfTokenVal = await csrfToken.getOrSetCsrfToken();
  const headers = getHeaders(csrfTokenVal);
  gtagActionResult(`check_account`, headers.headers['request-token']);
  const res = axios
    .post(
      `${AppConfig.apiEnv}/auth/check-account`,
      {
        email,
        recaptcha,
      },
      headers
    )
    .then((response) => {
      if (response.data.success) {
        gtagActionResult(
          `check_account`,
          headers.headers['request-token'],
          `success`
        );
        return response.data.message === 'yes';
      }
      throw new Error(response.data.message);
    })
    .catch((err) => {
      gtagActionResult(
        `check_account`,
        headers.headers['request-token'],
        getErrorMessageString(err)
      );
      throw getAxiosError(err);
    });
  return res;
};
