import AppConfig from '../AppConfig';
import { DeleteResponse } from '../interfaces/api';
import { axiosInstance, getAxiosError } from '../utils/axios';
import { FormatTextType, formatText } from '../utils/common';

export const getAllApi = async <T>(entityName: string): Promise<T[]> => {
  const entityNameLowerCase = formatText(entityName, FormatTextType.LOWER);
  const res = await axiosInstance()
    .get(`${AppConfig.apiEnv}/v1/${entityNameLowerCase}`)
    .then((response) => response.data as T[])
    .catch((err) => {
      throw getAxiosError(err);
    });
  return res;
};

export const getByIdApi = async <T>(
  entityName: string,
  id: string
): Promise<T> => {
  const entityNameLowerCase = formatText(entityName, FormatTextType.LOWER);
  const res = await axiosInstance()
    .get(`${AppConfig.apiEnv}/v1/${entityNameLowerCase}/${id}`)
    .then((response) => response.data as T)
    .catch((err) => {
      throw getAxiosError(err);
    });
  return res;
};

export const getByFilterApi = async <T>(
  entityName: string,
  record: Partial<T>
): Promise<T[]> => {
  const entityNameLowerCase = formatText(entityName, FormatTextType.LOWER);
  const res = await axiosInstance()
    .post(`${AppConfig.apiEnv}/v1/${entityNameLowerCase}/filter`, record)
    .then((response) => response.data as T[])
    .catch((err) => {
      throw getAxiosError(err);
    });
  return res;
};

export const addApi = async <T>(
  entityName: string,
  record: Partial<T>
): Promise<T> => {
  const entityNameLowerCase = formatText(entityName, FormatTextType.LOWER);
  const res = await axiosInstance()
    .post(`${AppConfig.apiEnv}/v1/${entityNameLowerCase}`, record)
    .then((response) => response.data as T)
    .catch((err) => {
      throw getAxiosError(err);
    });
  return res;
};

export const updateApi = async <T>(
  entityName: string,
  id: string,
  record: Partial<T>
): Promise<T> => {
  const entityNameLowerCase = formatText(entityName, FormatTextType.LOWER);
  const res = await axiosInstance()
    .put(`${AppConfig.apiEnv}/v1/${entityNameLowerCase}/${id}`, record)
    .then((response) => response.data as T)
    .catch((err) => {
      throw getAxiosError(err);
    });
  return res;
};

export const deleteByIdApi = async (
  entityName: string,
  id: string
): Promise<string> => {
  const entityNameLowerCase = formatText(entityName, FormatTextType.LOWER);
  const res = await axiosInstance()
    .delete(`${AppConfig.apiEnv}/v1/${entityNameLowerCase}/${id}`)
    .then((response) => response.data as string)
    .catch((err) => {
      throw getAxiosError(err);
    });
  return res;
};

export const deleteByIdsApi = async (
  entityName: string,
  ids: string[]
): Promise<DeleteResponse[]> => {
  const entityNameLowerCase = formatText(entityName, FormatTextType.LOWER);
  const res = await axiosInstance()
    .delete(`${AppConfig.apiEnv}/v1/${entityNameLowerCase}`, { data: { ids } })
    .then((response) => response.data as DeleteResponse[])
    .catch((err) => {
      throw getAxiosError(err);
    });
  return res;
};
