import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

const axiosHeader = () => {
  const authToken: any = JSON.parse(
    `${window.sessionStorage.getItem('x_access_token')}`
  );
  return {
    'Content-Type': 'application/json',
    'X-Request-Id': uuidv4(),
    'X-Client': 'WebView',
    Authorization: `Bearer ${
      authToken && authToken.access_token
        ? authToken.access_token
        : 'MissingToken'
    }`,
  };
};

export const axiosInstance = () =>
  axios.create({
    headers: axiosHeader(),
    timeout: 10000,
  });

export const getAxiosError = (error: any) => {
  if (error.response) {
    return error.response.data;
  }
  if (error.request) {
    return error.request;
  }
  return error.message;
};
