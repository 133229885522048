import { UserFull } from './user';

export enum ListType {
  SIMPLE = 'SIMPLE',
  STOCK = 'STOCK',
}

export enum ShoppingListPermission {
  ADMIN = 'ADMIN',
  VIEW = 'VIEW',
  EDIT = 'EDIT',
}

export interface ShoppingListFriend {
  id: string;
  shoppingListId: string;
  friendId: string;
  permission: ShoppingListPermission;
}

export interface ShoppingList {
  id: string;
  permission: ShoppingListPermission;
  sharedByName: string;
  userId: number;
  name: string;
  listType: ListType;
  favourite: boolean;
  itemTags?: ItemTag[];
  shoppingListFriends?: ShoppingListFriend[];
  notes?: string;
  order: number;
  items?: Item[];
  createdAt: string;
  updatedAt: string;
}

export interface Unit {
  id: string;
  name: string;
  abbreviation: string;
}

export interface ItemTag {
  id?: string;
  name: string;
  color: string;
}

export interface Item {
  id: string;
  shoppingListId: string;
  name: string;
  quantityInStock?: number;
  quantityToPurchase?: number;
  unit?: Unit;
  purchased: boolean;
  itemTags?: ItemTag[];
  notes?: string;
  order: number;
  createdAt: string;
  updatedAt: string;
}

export interface UserConfig {
  id: string;
  userId: number;
  shoppingListId: string | null;
  unitId: string | null;
  shoppingListFilters: string;
  itemFilters: string;
  shoppingListConfirmDelete: boolean;
  itemConfirmDelete: boolean;
  createdAt: string;
  updatedAt: string;
}

export enum FeedbackStatus {
  RECEIVED = 'RECEIVED',
  TODO = 'TODO',
  INPROGRESS = 'INPROGRESS',
  TESTING = 'TESTING',
  FIXED = 'FIXED',
  DUPLICATE = 'DUPLICATE',
  NOTANISSUE = 'NOTANISSUE',
  WONTFIX = 'WONTFIX',
  CANTREPRODUCE = 'CANTREPRODUCE',
  NEEDMOREINFO = 'NEEDMOREINFO',
  POSITIVE = 'POSITIVE',
  NEGATIVE = 'NEGATIVE',
}

export interface Feedback {
  id: string;
  userId: number;
  user?: UserFull;
  feedback: string;
  reply?: string;
  note?: string;
  rating: number;
  status?: FeedbackStatus;
  createdAt?: string;
  updatedAt?: string;
}

export enum FriendshipStatus {
  INVITED = 'INVITED',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  BLOCKED = 'BLOCKED',
}
export interface FriendUser {
  userId: number;
  firstName: string;
  lastName: string;
  email?: string;
}

export interface Friend {
  id: string;
  friendId: number;
  myStatus: FriendshipStatus;
  firendStatus: FriendshipStatus;
  friendUser?: FriendUser;
  createdAt?: string;
  updatedAt?: string;
}

export interface FriendRequestResponse {
  message: string;
  friend?: Friend;
}
