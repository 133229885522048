import { gql } from 'apollo-boost';
import { v4 as uuidv4 } from 'uuid';

import { client } from '../utils/graphqlclient';
import { gtagActionResult } from '../utils/gtag';
import { getErrorMessageString } from '../utils/api';
import { getQueryFields } from '../utils/graphqlFields';
import {
  FriendRequestResponse,
  FriendUser,
  ShoppingList,
} from '../interfaces/backend';

export const resetShoppingListApi = async (id: string): Promise<boolean> => {
  const requestToken = uuidv4();
  gtagActionResult(`shopping_list_reset`, requestToken, id);
  const RESET_SHOPPING_LIST = gql`
    mutation resetShoppingList($id: String!) {
      resetShoppingList(id: $id)
    }
  `;

  const res = await client
    .mutate({
      mutation: RESET_SHOPPING_LIST,
      variables: { id },
      fetchPolicy: 'no-cache',
      context: {
        headers: {
          'request-token': requestToken,
        },
      },
    })
    .then((response) => {
      gtagActionResult(`shopping_list_reset_result`, requestToken, 'success');
      return response.data.resetShoppingList as boolean;
    })
    .catch((e) => {
      gtagActionResult(
        `shopping_list_reset_result`,
        requestToken,
        getErrorMessageString(e)
      );
      throw e;
    });
  return res;
};

export const updateItemsOrderApi = async (
  shoppingListId: string,
  itemIds: string[]
): Promise<boolean> => {
  const requestToken = uuidv4();
  gtagActionResult(`item_update_order`, requestToken, `${shoppingListId}`);
  const UPDATE_ITEMS_ORDER = gql`
    mutation updateItemsOrder($shoppingListId: String!, $itemIds: [String!]!) {
      updateItemsOrder(shoppingListId: $shoppingListId, itemIds: $itemIds)
    }
  `;

  const res = await client
    .mutate({
      mutation: UPDATE_ITEMS_ORDER,
      variables: { shoppingListId, itemIds },
      fetchPolicy: 'no-cache',
      context: {
        headers: {
          'request-token': requestToken,
        },
      },
    })
    .then((response) => {
      gtagActionResult(`item_update_order_result`, requestToken, 'success');
      return response.data.updateItemsOrder as boolean;
    })
    .catch((e) => {
      gtagActionResult(
        `item_update_order_result`,
        requestToken,
        getErrorMessageString(e)
      );
      throw e;
    });
  return res;
};

export const updateShoppingListsOrderApi = async (
  userId: number,
  shoppingListIds: string[]
): Promise<boolean> => {
  const requestToken = uuidv4();
  gtagActionResult(
    `shopping_list_update_order`,
    requestToken,
    userId.toString()
  );
  const UPDATE_SHOPPING_LIST_ORDER = gql`
    mutation updateShoppingListsOrder(
      $userId: Float!
      $shoppingListIds: [String!]!
    ) {
      updateShoppingListsOrder(
        userId: $userId
        shoppingListIds: $shoppingListIds
      )
    }
  `;

  const res = await client
    .mutate({
      mutation: UPDATE_SHOPPING_LIST_ORDER,
      variables: { userId, shoppingListIds },
      fetchPolicy: 'no-cache',
      context: {
        headers: {
          'request-token': requestToken,
        },
      },
    })
    .then((response) => {
      gtagActionResult(
        `shopping_list_update_order_result`,
        requestToken,
        'success'
      );
      return response.data.updateShoppingListsOrder as boolean;
    })
    .catch((e) => {
      gtagActionResult(
        `shopping_list_update_order_result`,
        requestToken,
        getErrorMessageString(e)
      );
      throw e;
    });
  return res;
};

export const swapShoppingListItems = async (
  firstShoppingListId: string | undefined,
  secondShoppingListId: string | undefined,
  firstShoppingListItemIds: string[],
  secondShoppingListItemIds: string[]
): Promise<boolean> => {
  const requestToken = uuidv4();
  gtagActionResult(
    `shopping_list_item_swap`,
    requestToken,
    `${firstShoppingListId}_${secondShoppingListId}`
  );
  const SWAP_SHOPPING_LIST_ITEMS = gql`
    mutation swapShoppingListItems(
      $firstShoppingListId: String
      $secondShoppingListId: String
      $firstShoppingListItemIds: [String!]!
      $secondShoppingListItemIds: [String!]!
    ) {
      swapShoppingListItems(
        firstShoppingListId: $firstShoppingListId
        secondShoppingListId: $secondShoppingListId
        firstShoppingListItemIds: $firstShoppingListItemIds
        secondShoppingListItemIds: $secondShoppingListItemIds
      )
    }
  `;

  const res = await client
    .mutate({
      mutation: SWAP_SHOPPING_LIST_ITEMS,
      variables: {
        firstShoppingListId,
        secondShoppingListId,
        firstShoppingListItemIds,
        secondShoppingListItemIds,
      },
      fetchPolicy: 'no-cache',
      context: {
        headers: {
          'request-token': requestToken,
        },
      },
    })
    .then((response) => {
      gtagActionResult(
        `shopping_list_item_swap_result`,
        requestToken,
        'success'
      );
      return response.data.swapShoppingListItems as boolean;
    })
    .catch((e) => {
      gtagActionResult(
        `shopping_list_item_swap_result`,
        requestToken,
        getErrorMessageString(e)
      );
      throw e;
    });
  return res;
};

export const copyShoppingListApi = async <T>(
  entityName: string,
  id: string,
  name: string
): Promise<T> => {
  const requestToken = uuidv4();
  gtagActionResult(`${entityName}_copy`, requestToken);
  const COPY_ENTITY = gql`
    mutation copy${entityName} ($id: String!, $name: String!) {
      copy${entityName} (id: $id,name: $name) {
        ${getQueryFields(entityName)}
      }
    }
  `;
  const res = await client
    .mutate({
      mutation: COPY_ENTITY,
      variables: { id, name },
      fetchPolicy: 'no-cache',
      context: {
        headers: {
          'request-token': requestToken,
        },
      },
    })
    .then((response) => {
      gtagActionResult(`${entityName}_copy_result`, requestToken, 'success');
      return response.data[`copy${entityName}`] as T;
    })
    .catch((e) => {
      gtagActionResult(
        `${entityName}_copy_result`,
        requestToken,
        getErrorMessageString(e)
      );
      throw e;
    });
  return res;
};

export const sampleShoppingListApi = async (): Promise<ShoppingList> => {
  const requestToken = uuidv4();
  gtagActionResult(`ShoppingList_sample`, requestToken);
  const COPY_ENTITY = gql`
    mutation sampleShoppingList {
      sampleShoppingList {
        ${getQueryFields('ShoppingList')}
      }
    }
  `;
  const res = await client
    .mutate({
      mutation: COPY_ENTITY,
      fetchPolicy: 'no-cache',
      context: {
        headers: {
          'request-token': requestToken,
        },
      },
    })
    .then((response) => {
      gtagActionResult(`ShoppingList_sample_result`, requestToken, 'success');
      return response.data.sampleShoppingList as ShoppingList;
    })
    .catch((e) => {
      gtagActionResult(
        `ShoppingList_sample_result`,
        requestToken,
        getErrorMessageString(e)
      );
      throw e;
    });
  return res;
};

export const getFriendByInviteIdApi = async (
  inviteId: string
): Promise<FriendUser> => {
  const requestToken = uuidv4();
  gtagActionResult(`get_friend_by_id`, requestToken);

  const GET_FRIEND_USER = gql`
  query getFriendByInviteId ($inviteId: String!) {
    getFriendByInviteId (inviteId: $inviteId) {
      ${getQueryFields('FriendUser')}
    }
  }
`;
  const res = await client
    .query({
      query: GET_FRIEND_USER,
      variables: { inviteId },
      fetchPolicy: 'no-cache',
      context: {
        headers: {
          'request-token': requestToken,
        },
      },
    })
    .then((response) => {
      gtagActionResult(`get_friend_by_id_result`, requestToken, 'success');
      return response.data.getFriendByInviteId as FriendUser;
    })
    .catch((e) => {
      gtagActionResult(
        `get_friend_by_id_result`,
        requestToken,
        getErrorMessageString(e)
      );
      throw e;
    });
  return res;
};

export const sendFriendRequestByEmailApi = async (
  email: string
): Promise<FriendRequestResponse> => {
  const requestToken = uuidv4();
  gtagActionResult(`send_friend_request_by_email`, requestToken);

  const SEND_FRIEND_REQUEST = gql`
  mutation sendFriendRequestByEmail ($email: String!) {
    sendFriendRequestByEmail (email: $email) {
      message
      friend {
        ${getQueryFields('Friend')}
      }
    }
  }
`;

  const res = await client
    .mutate({
      mutation: SEND_FRIEND_REQUEST,
      variables: { email },
      fetchPolicy: 'no-cache',
      context: {
        headers: {
          'request-token': requestToken,
        },
      },
    })
    .then((response) => {
      gtagActionResult(
        `send_friend_request_by_email_result`,
        requestToken,
        'success'
      );
      return response.data.sendFriendRequestByEmail as FriendRequestResponse;
    })
    .catch((e) => {
      gtagActionResult(
        `send_friend_request_by_email_result`,
        requestToken,
        getErrorMessageString(e)
      );
      throw e;
    });
  return res;
};
