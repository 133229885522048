import AppConfig from '../AppConfig';
import { DeleteResponse } from '../interfaces/api';

import {
  getAllApi as getAllApiAxios,
  getByIdApi as getByIdApiAxios,
  getByFilterApi as getByFilterApiAxios,
  addApi as addApiAxios,
  updateApi as updateApiAxios,
  deleteByIdApi as deleteByIdApiAxios,
  deleteByIdsApi as deleteByIdsApiAxios,
} from './commonApisAxios';
import {
  getAllApi as getAllApiGraph,
  getByIdApi as getByIdApiGraph,
  getByFilterApi as getByFilterApiGraph,
  addApi as addApiGraph,
  updateApi as updateApiGraph,
  deleteByIdApi as deleteByIdApiGraph,
  deleteByIdsApi as deleteByIdsApiGraph,
} from './commonApisGraph';

export const getAllApi = async <T>(entityName: string): Promise<T[]> => {
  if (AppConfig.stubbed) {
    return getAllApiAxios(entityName);
  }
  return getAllApiGraph(entityName);
};

export const getByIdApi = async <T>(
  entityName: string,
  id: string
): Promise<T> => {
  if (AppConfig.stubbed) {
    return getByIdApiAxios(entityName, id);
  }
  return getByIdApiGraph(entityName, id);
};

export const getByFilterApi = async <T>(
  entityName: string,
  record: Partial<T>
): Promise<T[]> => {
  if (AppConfig.stubbed) {
    return getByFilterApiAxios(entityName, record);
  }
  return getByFilterApiGraph(entityName, record);
};

export const addApi = async <T>(
  entityName: string,
  record: Partial<T>
): Promise<T> => {
  if (AppConfig.stubbed) {
    return addApiAxios(entityName, record);
  }
  return addApiGraph(entityName, record);
};

export const updateApi = async <T>(
  entityName: string,
  id: string,
  record: Partial<T>
): Promise<T> => {
  if (AppConfig.stubbed) {
    return updateApiAxios(entityName, id, record);
  }
  return updateApiGraph(entityName, id, record);
};

export const deleteByIdApi = async (
  entityName: string,
  id: string
): Promise<string> => {
  if (AppConfig.stubbed) {
    return deleteByIdApiAxios(entityName, id);
  }
  return deleteByIdApiGraph(entityName, id);
};

export const deleteByIdsApi = async (
  entityName: string,
  ids: string[]
): Promise<DeleteResponse[]> => {
  if (AppConfig.stubbed) {
    return deleteByIdsApiAxios(entityName, ids);
  }
  return deleteByIdsApiGraph(entityName, ids);
};
