export const stringSorter = (
  a: string,
  b: string,
  ascending: boolean = true
) => {
  if (ascending) {
    if (a.toLowerCase() < b.toLowerCase()) {
      return -1;
    }
    if (a.toLowerCase() > b.toLowerCase()) {
      return 1;
    }
  } else {
    if (a.toLowerCase() > b.toLowerCase()) {
      return -1;
    }
    if (a.toLowerCase() < b.toLowerCase()) {
      return 1;
    }
  }
  return 0;
};

export const booleanSorter = (a: boolean, b: boolean) => {
  if (a && !b) {
    return -1;
  }
  return 1;
};

export const numberSorter = (
  a: number,
  b: number,
  ascending: boolean = true
) => {
  if (ascending) {
    return a - b;
  }
  return b - a;
};
