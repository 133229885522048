class AppConfig {
  public readonly downtime?: boolean;

  public readonly stubbed?: boolean;

  public readonly sendAnalytics?: boolean;

  public readonly apiEnv: string;

  public readonly debugGraphql: boolean;

  public readonly recaptchaSiteKey: string;

  constructor() {
    this.downtime = process.env.REACT_APP_DOWNTIME?.trim() === 'true';
    this.stubbed = process.env.REACT_APP_STUBBED?.trim() === 'true';
    this.sendAnalytics =
      process.env.REACT_APP_SEND_ANALYTICS?.trim() === 'true';
    this.apiEnv = process.env.REACT_APP_API_ENV as string;
    this.debugGraphql = process.env.REACT_APP_DEBUG_GRAPHQL?.trim() === 'true';
    this.recaptchaSiteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY as string;
  }
}

export default new AppConfig();
