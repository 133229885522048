import React, { Suspense, useEffect } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ConfigProvider } from 'antd';

import './App.scss';
import colors from './colors.module.scss';
import ScrollToTop from './components/scrollToTop/scrollToTop';
import LoadingScreen from './pagesStandAlone/LoadingScreen/LoadingScreen';
import Notification, {
  NotifactionNote,
  NotificationType,
} from './components/notification/notification';

const WebTemplate = React.lazy(() => import('./template/web/web'));
const ProductTemplate = React.lazy(() => import('./template/product/product'));

// Pages
const Home = React.lazy(() => import('./pages/home/home'));
const Contact = React.lazy(() => import('./pages/contact/contact'));
const News = React.lazy(() => import('./pages/news/news'));
const About = React.lazy(() => import('./pages/about/about'));
const Inspect = React.lazy(() => import('./pages/inspect/inspect'));
const Login = React.lazy(() => import('./pages/login/login'));
const Invite = React.lazy(() => import('./pages/invite/invite'));
const SignUp = React.lazy(() => import('./pages/signup/signup'));
const ForgotPassword = React.lazy(
  () => import('./pages/forgotPassword/forgotPassword')
);
const PrivacyPolicy = React.lazy(
  () => import('./pages/privacyPolicy/privacyPolicy')
);
const VerifyEmail = React.lazy(() => import('./pages/verifyEmail/verifyEmail'));
const Logout = React.lazy(() => import('./pages/logout/logout'));
const RecoverPassword = React.lazy(
  () => import('./pages/recoverPassword/recoverPassword')
);
const ResendVerificationEmail = React.lazy(
  () => import('./pages/resendVerificationEmail/resendVerificationEmail')
);

const UnlockAccount = React.lazy(
  () => import('./pages/unlockAccount/unlockAccount')
);

// Product Pages
const Dashboard = React.lazy(
  () => import('./pagesProduct/dashboard/dashboard')
);
const ShoppingListComp = React.lazy(
  () => import('./pagesProduct/shoppinglist/shoppinglist')
);
const Account = React.lazy(() => import('./pagesProduct/account/account'));
const DeleteUser = React.lazy(
  () => import('./pagesProduct/account/DeleteUser/DeleteUser')
);
const ItemComp = React.lazy(() => import('./pagesProduct/item/item'));
const Feedback = React.lazy(() => import('./pagesProduct/feedback/feedback'));
const Friends = React.lazy(() => import('./pagesProduct/friends/friends'));

// Admin Pages

const AdminDashboard = React.lazy(
  () => import('./pagesProduct/admin/dashboard/dashboard')
);

const AdminFeedback = React.lazy(
  () => import('./pagesProduct/admin/feedback/feedback')
);

// Mobile App Pages
const Landing = React.lazy(() => import('./pagesMobile/landing/landing'));

const App = () => {
  const [note, setNote] = React.useState<NotifactionNote>();

  useEffect(() => {
    const reloadError = localStorage.getItem('RELOAD_ERROR');
    if (reloadError) {
      setNote({ type: NotificationType.ERROR, message: reloadError });
      localStorage.removeItem('RELOAD_ERROR');
    }
  }, []);

  return (
    <>
      <Notification note={note} />
      <ConfigProvider
        theme={{
          token: {
            colorBgBase: colors.backgroundPrimary,
            colorTextBase: colors.textPrimary,
            colorPrimary: colors.linkActivePrimary,
            colorLink: colors.linkPrimary,
            colorTextHeading: colors.headingPrimary,
          },
          components: {
            Layout: {
              headerBg: colors.backgroundPrimary,
              bodyBg: colors.backgroundPrimary,
            },
          },
        }}
      >
        <BrowserRouter>
          <ScrollToTop />
          <Routes>
            <Route
              path="/errorlogout"
              element={
                <Suspense fallback={<LoadingScreen />}>
                  <Logout isError />
                </Suspense>
              }
            />
            <Route
              path="/logout"
              element={
                <Suspense fallback={<LoadingScreen />}>
                  <Logout />
                </Suspense>
              }
            />
            <Route
              path="/"
              element={
                <Suspense fallback={<LoadingScreen />}>
                  <WebTemplate />
                </Suspense>
              }
            >
              <Route
                index
                element={
                  <Suspense fallback={<LoadingScreen />}>
                    <Home />
                  </Suspense>
                }
              />
              <Route
                path="blog"
                element={
                  <Suspense fallback={<LoadingScreen />}>
                    <News />
                  </Suspense>
                }
              />
              <Route
                path="about"
                element={
                  <Suspense fallback={<LoadingScreen />}>
                    <About />
                  </Suspense>
                }
              />
              <Route
                path="inspect"
                element={
                  <Suspense fallback={<LoadingScreen />}>
                    <Inspect />
                  </Suspense>
                }
              />
              <Route
                path="contact"
                element={
                  <Suspense fallback={<LoadingScreen />}>
                    <Contact />
                  </Suspense>
                }
              />
              <Route
                path="login"
                element={
                  <Suspense fallback={<LoadingScreen />}>
                    <Login />
                  </Suspense>
                }
              />
              <Route
                path="invite"
                element={
                  <Suspense fallback={<LoadingScreen />}>
                    <Invite />
                  </Suspense>
                }
              />
              <Route
                path="signup"
                element={
                  <Suspense fallback={<LoadingScreen />}>
                    <SignUp />
                  </Suspense>
                }
              />
              <Route
                path="forgotpassword"
                element={
                  <Suspense fallback={<LoadingScreen />}>
                    <ForgotPassword />
                  </Suspense>
                }
              />
              <Route
                path="reset-password"
                element={
                  <Suspense fallback={<LoadingScreen />}>
                    <RecoverPassword />
                  </Suspense>
                }
              />
              <Route
                path="verify-email"
                element={
                  <Suspense fallback={<LoadingScreen />}>
                    <VerifyEmail />
                  </Suspense>
                }
              />
              <Route
                path="resend-verification-email"
                element={
                  <Suspense fallback={<LoadingScreen />}>
                    <ResendVerificationEmail />
                  </Suspense>
                }
              />
              <Route
                path="unlock-account"
                element={
                  <Suspense fallback={<LoadingScreen />}>
                    <UnlockAccount />
                  </Suspense>
                }
              />
              <Route
                path="privacy"
                element={
                  <Suspense fallback={<LoadingScreen />}>
                    <PrivacyPolicy />
                  </Suspense>
                }
              />
              <Route
                path="*"
                element={
                  <Suspense fallback={<LoadingScreen />}>
                    <Home />
                  </Suspense>
                }
              />
            </Route>
            <Route
              path="/product"
              element={
                <Suspense fallback={<LoadingScreen />}>
                  <ProductTemplate />
                </Suspense>
              }
            >
              <Route
                index
                element={
                  <Suspense fallback={<LoadingScreen />}>
                    <ShoppingListComp />
                  </Suspense>
                }
              />
              <Route
                path="shoppinglist"
                element={
                  <Suspense fallback={<LoadingScreen />}>
                    <ShoppingListComp />
                  </Suspense>
                }
              />
              <Route
                path="dashboard"
                element={
                  <Suspense fallback={<LoadingScreen />}>
                    <Dashboard />
                  </Suspense>
                }
              />
              <Route
                path="feedback"
                element={
                  <Suspense fallback={<LoadingScreen />}>
                    <Feedback />
                  </Suspense>
                }
              />
              <Route
                path="friends"
                element={
                  <Suspense fallback={<LoadingScreen />}>
                    <Friends />
                  </Suspense>
                }
              />
              <Route
                path="account/delete"
                element={
                  <Suspense fallback={<LoadingScreen />}>
                    <DeleteUser />
                  </Suspense>
                }
              />
              <Route
                path="account"
                element={
                  <Suspense fallback={<LoadingScreen />}>
                    <Account />
                  </Suspense>
                }
              />
              <Route
                path="item"
                element={
                  <Suspense fallback={<LoadingScreen />}>
                    <ItemComp />
                  </Suspense>
                }
              />
              <Route
                path="*"
                element={
                  <Suspense fallback={<LoadingScreen />}>
                    <Dashboard />
                  </Suspense>
                }
              />
            </Route>

            <Route
              path="/admin"
              element={
                <Suspense fallback={<LoadingScreen />}>
                  <ProductTemplate />
                </Suspense>
              }
            >
              <Route
                index
                element={
                  <Suspense fallback={<LoadingScreen />}>
                    <AdminDashboard />
                  </Suspense>
                }
              />
              <Route
                path="dashboard"
                element={
                  <Suspense fallback={<LoadingScreen />}>
                    <AdminDashboard />
                  </Suspense>
                }
              />
              <Route
                path="feedback"
                element={
                  <Suspense fallback={<LoadingScreen />}>
                    <AdminFeedback />
                  </Suspense>
                }
              />
              <Route
                path="*"
                element={
                  <Suspense fallback={<LoadingScreen />}>
                    <AdminDashboard />
                  </Suspense>
                }
              />
            </Route>
            <Route path="/app">
              <Route
                index
                element={
                  <Suspense fallback={<LoadingScreen />}>
                    <Landing />
                  </Suspense>
                }
              />
              <Route
                path="*"
                element={
                  <Suspense fallback={<LoadingScreen />}>
                    <Landing />
                  </Suspense>
                }
              />
            </Route>
          </Routes>
        </BrowserRouter>
      </ConfigProvider>{' '}
    </>
  );
};
export default App;
