import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import AppConfig from '../AppConfig';
import { TIME_OUT } from '../utils/constants';
import { gtagActionResult } from '../utils/gtag';
import { getUserToken } from '../utils/requetToken';

export const csrfTokenApi = async () => {
  const requestToken = uuidv4();
  gtagActionResult(`csrf`, requestToken, undefined);

  /* eslint-disable no-await-in-loop */
  for (let i = 0; i < 5; i += 1) {
    let csrfTokenVal = '';
    await axios
      .get(`${AppConfig.apiEnv}/csrftoken`, {
        withCredentials: true,
        headers: {
          'request-token': requestToken,
          'user-token': getUserToken(),
        },
        timeout: TIME_OUT,
      })
      .then((res: any) => {
        gtagActionResult(`csrf_result`, requestToken, `success`);
        csrfTokenVal = res.data;
      })
      .catch(() => {
        gtagActionResult(`csrf_result`, requestToken, `fail`);
      });

    if (csrfTokenVal) {
      return csrfTokenVal;
    }
  }
  /* eslint-enable no-await-in-loop */
  return '';
};
