import { createAsyncThunk } from '@reduxjs/toolkit';
import { updateApi, getByFilterApi } from '../../../apis/commonApis';
import { UserConfig } from '../../../interfaces/backend';
import { ReduxError } from '../../../interfaces/redux';
import { getErrorMessage } from '../../../utils/api';
import { FormatTextType, formatText } from '../../../utils/common';

const entityName = 'UserConfig';
const entityNameLowerCase = formatText(entityName, FormatTextType.LOWER);

export const getByFilterUserConfig = createAsyncThunk<
  UserConfig[],
  Partial<UserConfig>
>(
  `${entityNameLowerCase}/getByFIlter`,
  async (record: Partial<UserConfig>, { rejectWithValue }) => {
    try {
      return await getByFilterApi(entityName, record);
    } catch (err) {
      return rejectWithValue({
        error: JSON.stringify(err),
        message: getErrorMessage(err),
        requestPayload: { record },
        url: `${entityNameLowerCase}/getByFIlter`,
      } as ReduxError);
    }
  }
);

export const updateUserConfig = createAsyncThunk<
  UserConfig,
  Partial<UserConfig>
>(
  `${entityNameLowerCase}/update`,
  async (record, { rejectWithValue, getState }) => {
    try {
      const state = getState() as any;
      const id = state?.userConfigStore?.data?.records?.[0]?.id;
      if (!id)
        return rejectWithValue({
          message: 'User Config not found',
        } as ReduxError);
      return await updateApi(entityName, id, record);
    } catch (err) {
      return rejectWithValue({
        error: JSON.stringify(err),
        message: getErrorMessage(err),
        requestPayload: { record },
        url: `${entityNameLowerCase}/update`,
      } as ReduxError);
    }
  }
);
