// --------------------------------
// ------   AUTHENTICATION   ------
// --------------------------------
export enum UserRole {
  ADMIN = 'ADMIN',
  SUPPORTL1 = 'SUPPORTL1',
  SUPPORTL2 = 'SUPPORTL2',
  SUPPORTL3 = 'SUPPORTL3',
  DIAMOND = 'DIAMOND',
  SILVER = 'SILVER',
  GOLD = 'GOLD',
}

export interface User {
  userId: number;
  firstName: string;
  lastName: string;
  role: UserRole;
  sessions?: Session[];
  emailVerified: boolean;
  phoneVerified: boolean;
  recaptchaToken: string;
  inviteId: string;
  createdAt?: string;
  updatedAt?: string;
}

// Need to keep sensitive data separate token data
export interface UserFull extends User {
  email: string;
  phone: string;
}

export interface Session {
  sessionId: string;
  userId: number;
  userAgent?: string;
  ipAddress?: string;
  createdAt?: string;
  updatedAt?: string;
}
